<template>
  <div>
    <div>
      <component
        :is="currentTabComponent"
        :grupo="grupo"
        :propEditPessoa="propEditPessoa"
        :key="childKey"
        @trocaComponente="trocaComponente"
        @reiniciaComponente="reiniciaComponente"
      >
      </component>
    </div>
  </div>
</template>

<script>
import GrupoPessoasListar from "./GrupoPessoasListar.vue";
import GrupoPessoasImportar from "./GrupoPessoasImportar.vue";
import GrupoPessoasStatusImportacao from "./GrupoPessoasStatusImportacao.vue";
import CriarPessoaForm from "./CriarPessoaForm.vue";
import EditarPessoaForm from "./EditarPessoaForm.vue";

export default {
  components: {
    GrupoPessoasListar,
    GrupoPessoasImportar,
    GrupoPessoasStatusImportacao,
    CriarPessoaForm,
    EditarPessoaForm
  },
  props: {
    PessoaAbaSelecionada: {
      type: String,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTabComponent: this.PessoaAbaSelecionada,
      propEditPessoa: null,
      childKey: 1
    };
  },
  methods: {
    trocaComponente(objetoComponente) {
      this.propEditPessoa = objetoComponente.edicaoPessoa
      this.$emit("trocaComponente", objetoComponente);
    },
    reiniciaComponente() {
      this.childKey++
    }
  },
  watch: {
    PessoaAbaSelecionada() {
      this.currentTabComponent = this.PessoaAbaSelecionada;
    },
  },
};
</script>
