<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="EstadoCivil"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-EstadoCivil"
            v-model="EstadoCivilSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_estado_civil"
            label="descricao"
            :options="EstadoCivil"
            placeholder="Estado Civil"
            :disabled="Disabled"
            @input="SelecionaEstadoCivil"
          >
            <span slot="no-options">Nenhum Estado Civil encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BCol, BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
    BOverlay,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idEstadoCivil: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      EstadoCivilSelecionado: '',
      EstadoCivil: [],
      NomeLabel: 'Estado Civil',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_estado_civil) {
        this.EstadoCivilSelecionado = value
        return;
      }
      this.EstadoCivilSelecionado = null
    },
    idEstadoCivil(value) {
      this.EstadoCivilSelecionado = this.EstadoCivil.filter(
        x => x.id_estado_civil === value,
      )
      this.$emit('getEstadoCivil', this.EstadoCivilSelecionado[0])
    },

    disabledProp(value) {
      this.Disabled = value
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaEstadoCivilResponsavel()
  },
  methods: {
    SelecionaEstadoCivil() {
      this.$emit('getEstadoCivil', this.EstadoCivilSelecionado)
    },
    async buscaEstadoCivilResponsavel() {
      this.loading = true

      await this.$http.get(this.$api.EstadoCivilBusca).then(res => {
        this.EstadoCivil = res.data
        this.EstadoCivilSelecionado = this.EstadoCivil.filter(
          x => x.id_estado_civil === this.idEstadoCivil,
        )
        this.$emit('getEstadoCivil', this.EstadoCivilSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
