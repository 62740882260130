<template>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
        <div id="grupo-modulos">
            <div id="grupo-modulo" class="bg-white" v-if="Object.keys(grupo).length">
                <b-row class="mb-2">
                    <b-col md="12" sm="12"><h1>Módulos</h1></b-col>
                    <b-col md="12" sm="12">
                        <p class="mb-0 font-small-4">Serviços contratados pela empresa.</p>
                        <p class="mb-0 font-small-4">
                            Para adicionar um novo módulo, primeiro vincule o serviço
                            contratado nas configurações de Matriz/Filial.
                        </p>
                    </b-col>
                </b-row>
                <b-row v-if="showModulos">
                    <b-col md="4" v-for="modulo in listModulos" :key="modulo.id_modulo">
                        <div
                            class="card"
                            style="box-shadow: 0rem 0.8rem 2rem rgba(0, 0, 0, 0.2) !important"
                        >
                            <div class="card-body">
                                <b-row class="align-items-center">
                                    <b-col
                                        class="
                      font-medium-3 font-weight-bold
                      text-center text-md-left
                    "
                                    >
                                        {{ modulo.descricao }}
                                        <div
                                            v-if="isSaudeMental(modulo.id_modulo) === true"
                                            class="font-medium-1 text-muted"
                                        >
                                            <div
                                                v-if="checkArrayModuloAtivo(modulo.id_modulo) === true"
                                                class="text-success"
                                            >
                                                <div v-if="loadingQuestionarios">
                                                    <br />
                                                    <b-overlay :show="loadingQuestionarios" rounded="lg" opacity="0.6" />
                                                </div>
                                                <div v-else>
                                                     &blacksquare; Ativado {{formattedDescriptions}}
                                                </div>
                                            </div>
                                            <div v-else>&blacksquare; Desativado</div>
                                        </div>
                                         <div v-else class="font-medium-1 text-muted" >
                                            <div
                                                v-if="checkArrayModuloAtivo(modulo.id_modulo) === true"
                                                class="text-success"
                                            >
                                                &blacksquare; Ativado
                                            </div>
                                            <div v-else>&blacksquare; Desativado</div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <b-button-group>
                                <b-button
                                    style="
                                        border-top-left-radius: 0px;
                                        border-top-right-radius: 0px;
                                    "
                                    :disabled="!checkArrayModuloAtivo(modulo.id_modulo) === true || disabledConfigModulo(modulo)"
                                    variant="custom"
                                    class="font-weight-bold btn-gradient-"
                                    @click="configurarModulo(modulo)"
                                >
                                    Configurações
                                </b-button>

                                <b-button
                                    style="
                                        border-top-left-radius: 0px;
                                        border-top-right-radius: 0px;
                                    "
                                    v-if="checkArrayModuloAtivo(modulo.id_modulo) === false"
                                    variant="custom"
                                    class="font-weight-bold"
                                    @click="
                                        modalAtivaInativa(modulo.id_modulo, modulo, true)
                                    "
                                >
                                    Ativar
                                </b-button>
                                <b-button
                                    style="
                                        border-top-left-radius: 0px;
                                        border-top-right-radius: 0px;
                                    "
                                    v-else
                                    variant="custom"
                                    class="font-weight-bold"
                                    @click="
                                        modalAtivaInativa(modulo.id_modulo, modulo, false)
                                    "
                                >
                                    Desativar
                                </b-button>
                            </b-button-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <GrupoModulosConfiguracoes
            v-if="mostraModal"
            :modulo="moduloSelecionado"
            :grupo="grupo"
            @retornaModulos="reloadModulos"
            @closeModal="fechaModal"
        />
    </b-overlay>
</template>

<script>
import {
    BAlert,
    BBadge,
    BButton,
    BButtonGroup,
    BCard,
    BCardText,
    BCol,
    BModal,
    BOverlay,
    BRow,
    BSpinner,
} from "bootstrap-vue";
import GrupoModulosConfiguracoes from "./GrupoModulosConfiguracoes.vue";
import { modalGenericModel } from '@/libs/sweetalerts';
import { MODULOS, CHAVES_MODULOS } from "@/custom-enum/modulos-enum.js";
export default {
    components: {
        BRow,
        BButton,
        BButtonGroup,
        BCard,
        BModal,
        BBadge,
        BCol,
        BSpinner,
        BAlert,
        BCardText,
        BOverlay,
        GrupoModulosConfiguracoes,
    },
    props: {
        grupo: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            showModulos: true,
            disabled: true,
            loadingShow: true,
            modulos: this.grupo.modulos,
            moduloSelecionado: {},
            mostraModal: false,
            mostraLabelQuestionariosAtivos: false,
            listModulos: {},
            arrayModulosAtivos: [],
            questionariosEmpresa: [],
            permiteAtualizarAtivo: false,
            loadingQuestionarios: true,
            labelQuestionarios:' ',
            modal: {
                tipo: "ativar",
                show: false,
            },
            modalPermitido: {
                show: false,
            },
            dadosModulo: {
                id_modulo: null,
                descricao: "",
                ativar: false,
            },
        };
    },
    async mounted() {
        await this.preparaArrayModulosAtivos();
        this.retornaModulos();
        await this.preparaArrayQuestionariosAtivos();
    },
    methods: {
        isSaudeMental(idModulo){
          return idModulo === MODULOS.SAUDE_MENTAL;
        },
        async reloadModulos(){
            this.limpaDadosModulo();
            await this.preparaArrayModulosAtivos();
            this.retornaModulos();
            this.retornaModulosAtivos();
        },
        async retornaModulos() {
            this.loadingShow = true;

            await this.$http.get(this.$api.ModulosBusca).then((res) => {
                this.listModulos = res.data;
                this.stopLoad();
            });

            this.loadingShow = false;
        },
        async retornaModulosAtivos() {
            await this.$http.get((this.$api.ModuloEmpresa.replace('{idEmpresaGrupo}', this.grupo.id_empresa_grupo))).then((res) => {
                this.modulos = res.data;
                this.preparaArrayModulosAtivos();
                this.checkArrayModuloAtivo();

                this.stopLoad()
            });
        },
        preparaArrayModulosAtivos() {
            for (let modulo in this.modulos) {
                this.arrayModulosAtivos.push(this.modulos[modulo].id_modulo);
            }
        },
        checkArrayModuloAtivo(_idModulo) {
            for (let modAtivo in this.arrayModulosAtivos) {
                if (this.arrayModulosAtivos[modAtivo] === _idModulo) {
                    return true;
                }
            }
            return false;
        },
        async ativarInativarModulo(){
            this.showModulos = false;
            this.modal.show = false;
            this.loadingShow = true;
            await this.$http.put(this.$api.ModuloAlteraStatus, {
                    id_modulo: this.dadosModulo.id_modulo,
                    id_empresa_grupo: this.grupo.id_empresa_grupo
                })
                .then((_response) => {
                    modalGenericModel(
                        'Sucesso!',
                        (this.dadosModulo.ativar === false ? 'Módulo DESATIVADO com sucesso' : 'Módulo ATIVADO com sucesso'),
                        require('@/assets/custom-icons/cora-icons/success.png'),
                        'Ok'
                    )
                    this.limpaDadosModulo()
                    this.retornaModulos()
                    this.retornaModulosAtivos()
                })
                .catch((error) => {
                    this.limpaDadosModulo()
                    this.loadingShow = true;
                    this.retornaModulos()
                    this.retornaModulosAtivos()
                    modalGenericModel(
                        'Não foi possível realizar essa ação',
                        error.response.data.error,
                        require('@/assets/custom-icons/cora-icons/error.png'),
                        'Ok'
                    )
                })
        },

        limpaDadosModulo(){
            this.dadosModulo.id_modulo = null;
            this.dadosModulo.descricao = null;
            this.dadosModulo.ativar = null;

            this.modulos = {};
            this.listModulos = {};
            this.arrayModulosAtivos = [];
        },

        stopLoad(){
            this.showModulos = true;
            this.loadingShow = false;
        },

        modalSaudeMental(_modulo){
            modalGenericModel(
                'Deseja ativar o módulo saúde mental?',
                'Para isso, você precisa escolher um questionário na página a seguir',
                require('@/assets/custom-icons/cora-icons/warning.png'),
                'Sim, ativar',
                'Cancelar'
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.configurarModulo(_modulo);
                }else{
                    return ;
                }
            });
        },

        modalOutrosModulos(_idModulo,_modulo,_status){
            let textoBotao = this.dadosModulo.ativar ? 'ativar' : 'desativar';
                modalGenericModel(
                    'Confirme antes de prosseguir',
                    this.dadosModulo.ativar ? 'Tem certeza que deseja ATIVAR o módulo?'
                    : 'Tem certeza que deseja DESATIVAR o módulo?',
                    this.dadosModulo.ativar ? require('@/assets/custom-icons/cora-icons/warning.png')
                    : require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
                    'Sim, ' + textoBotao,
                    'Cancelar'
                ).then((result) => {
                    if(result.isConfirmed){
                        this.dadosModulo.id_modulo = _idModulo;
                        this.dadosModulo.descricao = _modulo.descricao;
                        this.dadosModulo.ativar = _status;
                        this.ativarInativarModulo()
                    }
                });
        },

        modalAtivaInativa(_idModulo, _modulo, _status=false){
            this.dadosModulo.id_modulo = _idModulo;
            this.dadosModulo.descricao = _modulo;
            this.dadosModulo.ativar = _status;
            if(_status && _idModulo === MODULOS.SAUDE_MENTAL){
               this.modalSaudeMental(_modulo);
            }else{
                this.modalOutrosModulos(_idModulo,_modulo,_status);
            }
        },

        fechaModal() {
            this.modal.show = false;
            this.modalPermitido.show = false;
            this.mostraModal = false;
            this.loadingShow = false;
            this.preparaArrayQuestionariosAtivos();
        },

        configurarModulo(modulo) {
            this.moduloSelecionado = modulo;
            this.mostraModal = true;
        },

        disabledConfigModulo(modulo){
            return modulo.id_modulo !== MODULOS.SAUDE_MENTAL;
        },


        async preparaArrayQuestionariosAtivos() {
            this.loadingShow = true;
            if(this.arrayModulosAtivos.includes(MODULOS.SAUDE_MENTAL)){
                this.loadingQuestionarios = true;
                await this.$nextTick();

                let params = {
                    id_empresa: this.grupo.id_empresa_matriz,
                    chave_modulo: CHAVES_MODULOS.SAUDE_MENTAL
                };
                try {
                    const res = await this.$http.get(this.$api.QuestionariosPorEmpresa, { params });
                    this.questionariosEmpresa = res.data;
                } catch (error) {
                    modalGenericModel(
                        'Não foi possível realizar essa ação',
                        error.response.data.error,
                        require('@/assets/custom-icons/exclamationMark.png'),
                        'ok'
                    );
                }
                this.loadingQuestionarios = false;
            }
            this.loadingShow = false;
        },
    },
    computed: {
        getDescritivoRegionais() {
            return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
                this.Matriz.regionais,
                "descricao",
                "Não informado"
            );
        },
        getDescritivoModulos() {
            return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
                this.grupo.modulos,
                "descricao",
                "Não possui"
            );
        },

        formattedDescriptions(){
            return Array.isArray(this.questionariosEmpresa)
                       ? this.questionariosEmpresa
                         .filter(item => item?.descricao)
                         .map(item => item.descricao)
                         .join(', ')
                       : '';
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/custom/grupo.scss";
</style>
