<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Lider"
      >
        <b-overlay
          :show="false"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Lider"
            v-model="LiderSelecionado"
            variant="custom"
            item-text="nome"
            item-value="id_colaborador"
            :required="required"
            label="nome"
            :options="Lider"
            placeholder="Líder"
            :disabled="Disabled"
            @input="SelecionaLider"
          >
            <span slot="no-options">Nenhum Líder encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório <br>
          </small>
          <small
            v-if="msgSelecioneEmpresa"
            class="text"
          >Selecione primeiro a Empresa</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BOverlay,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idLider: {
      type: Number,
      default: 0,
      required: false,
    },
    idEmpresa: {
      type: Number,
      required: false
    },
    disabledProp: {
      type: Boolean,
      default: true,
    },
    modoVisualizacao: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      LiderSelecionado: '',
      Lider: [],
      NomeLabel: 'Líder',
      Disabled: true,
      empresa: null,
      msgSelecioneEmpresa: true,
      inicio: true
    }
  },

  methods: {
    SelecionaLider() {
      this.$emit('getLider', this.LiderSelecionado)
    },
    async buscaLider() {
      this.loading = true

      const params = {
        id_empresa_grupo: this.$route.params.idGrupo,
        id_empresa: this.empresa,
        ativo: 'true'
      }

      await this.$http.get(this.$api.LiderBusca,
        { params }
      ).then(res => {
        this.Lider = res.data
        this.Lider.forEach(lider => {
          lider.nome = this.$helpers.removeAcentos(lider.nome) + ' - ' + this.formatarCPF(lider.cpf)
        })
        this.LiderSelecionado = this.Lider.filter(
          x => x.id_colaborador === this.idLider,
        )
        if (this.idLider > 0) this.$emit('getLider', this.LiderSelecionado[0])
      })

      this.loading = false
    },

    formatarCPF(cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  },

  watch: {
    values(value) {
      if (value?.id_colaborador) {
        this.LiderSelecionado = value
        return;
      }
      this.LiderSelecionado = null
    },

    idEmpresa(value) {
      if(value){
        this.empresa = value
        this.Disabled = this.modoVisualizacao ? true : false
        this.msgSelecioneEmpresa = false
        this.buscaLider()
      } else {
        this.Disabled = true
        this.empresa = null
        this.msgSelecioneEmpresa = true
      }
    },

    idLider(value) {
      this.LiderSelecionado = this.Lider.filter(
        x => x.id_colaborador === value,
      )
      this.$emit('getLider', this.LiderSelecionado[0])
    },

    disabledProp(value) {
      this.Disabled = value
    },

    required(value) {
      if (value) {
        this.NomeLabel = 'Líder *'
      } else {
        this.NomeLabel = 'Líder'
      }
    }
  }
}
</script>
