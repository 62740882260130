<template>
  <b-overlay
    :show="loadingShow"
    rounded="lg"
    opacity="1"
  >
    <validation-observer ref="simpleRules">
      <validation-provider
        #default="{ errors }"
        rules="required"
      >
        <b-form-group
          :state="errors.length > 0 ? false : null"
          label="Perfil *"
          label-for="Perfil"
        >
          <v-select
            id="Select-Perfil"
            multiple
            multiselect
            variant="custom"
            item-text="descricao"
            item-value="id_perfil"
            label="descricao"
            :options="computedOptions"
            :selectable="perfil => perfil.selectable"
            :value="perfisSelecionados"
            placeholder="Perfil"
            @input="alteraPerfilComponentePai"
            :style="disabledProp ? 'background-color: #f8f8f8;pointer-events:none;' : null"
          >
            <span slot="no-options">Nenhum Perfil encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { BCol, BFormGroup, BOverlay } from 'bootstrap-vue'
import { PERFIL } from '@/custom-enum/perfis-enum'

const perfilTrabalhador = PERFIL.TRABALHADOR

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
    BOverlay
  },

  props: {
    values: {
      type: Array,
      required: true
    },
    disabledProp: {
      type: Boolean,
      required: true
    },
  },

  computed: {
    isPerfilGestorSelecionado() {
      return this.perfisSelecionados.some(
        perfil => perfilTrabalhador !== (perfil.hasOwnProperty('id_perfil') ? perfil.id_perfil : null),
      )
    },
    isPerfilTrabalhadorSelecionado() {
      return this.perfisSelecionados.some(
        perfil => perfilTrabalhador === (perfil.hasOwnProperty('id_perfil') ? perfil.id_perfil : null),
      )
    },
    computedOptions() {
      return this.perfis.map(perfil => {
        const isPerfisGestoresSelecionaveis = this.isPerfilGestorSelecionado ? (perfil.id_perfil !== perfilTrabalhador) : true
        const isPerfilTrabalhadorSelecionavel = perfil.id_perfil === perfilTrabalhador
        const selectable = this.isPerfilTrabalhadorSelecionado ? isPerfilTrabalhadorSelecionavel : isPerfisGestoresSelecionaveis

        return {
          ...perfil,
          selectable,
        }
      })
    },
    selectedPerfis() {
      return this.values.map(
        value => this.computedOptions.find(perfil => perfil.id_perfil === value.id_perfil),
      )
    }
  },

  data() {
    return {
      loadingShow: true,
      perfisSelecionados: [],
      perfis: []
    }
  },

  async mounted() {
    await this.buscaPerfil()
    this.perfisSelecionados = this.selectedPerfis
  },

  methods: {
    async buscaPerfil() {
      await this.$http.get(this.$api.PerfilBusca,{
        params: { tipoPerfil: 2 }
      })
      .then((res) => {
        this.perfis = res.data
        this.loadingShow = false
      })
    },
    alteraPerfilComponentePai(arrayPerfis) {
      this.$emit('GetPerfil', arrayPerfis)
    }
  },

  watch: {
    values: {
      handler() {
        this.perfisSelecionados = this.selectedPerfis
      },
      deep: true
    },
  },
}
</script>
