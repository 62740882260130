import { getArrayAttr, strClear } from '@core/helpers'

const basePayload = formData => ({
  razao: formData.razao,
  nomeFantasia: formData.nomeFantasia,
  cep: strClear(formData.cep),
  idCidade: formData.cidade.id_cidade,
  regionais: getArrayAttr(formData.regionais, 'id_regiao'),
})

export const getPayloadCadastrarEmpresaMatriz = formData => {
  const payload = basePayload(formData)

  return {
    cnpj: strClear(formData.cnpj),
    modulos: getArrayAttr(formData.modulos, 'id_modulo'),
    ...payload,
  }
}

export const getPayloadEditarEmpresaMatriz = formData => {
  const payload = basePayload(formData)

  return {
    idResponsavel: formData.responsavel ? formData.responsavel.id_colaborador : null,
    modulos: getArrayAttr(formData.modulos, 'id_modulo'),
    ...payload,
  }
}

export const getPayloadCadastrarEmpresaFilial = formData => {
  const payload = basePayload(formData)

  return {
    cnpj: strClear(formData.cnpj),
    idEmpresaGrupo: formData.idEmpresaGrupo,
    ...payload,
  }
}

export const getPayloadEditarEmpresaFilial = formData => {
  const payload = basePayload(formData)

  return {
    idResponsavel: formData.responsavel ? formData.responsavel.id_colaborador : null,
    ...payload,
  }
}
