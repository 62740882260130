<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Turno"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Turno"
            v-model="TurnoSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_empresa_turno"
            label="descricao"
            :options="Turno"
            placeholder="Turno"
            :disabled="Disabled"
            @input="SelecionaTurno"
          >
            <span slot="no-options">Nenhum Turno encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BOverlay
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idTurno: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      TurnoSelecionado: '',
      Turno: [],
      NomeLabel: 'Turno',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_empresa_turno) {
        this.TurnoSelecionado = value
        return;
      }
      this.TurnoSelecionado = null
    },
    idTurno(value) {
      this.TurnoSelecionado = this.Turno.filter(
        x => x.id_empresa_turno === value,
      )
      this.$emit('getTurno', this.TurnoSelecionado[0])
    },
    disabledProp(value) {
      this.Disabled = value
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaTurno()
  },
  methods: {
    SelecionaTurno() {
      this.$emit('getTurno', this.TurnoSelecionado)
    },
    async buscaTurno() {
      this.loading = true

      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.TurnoBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      await this.$http.get(rota).then(res => {
        this.Turno = res.data
        this.TurnoSelecionado = this.Turno.filter(
          x => x.id_empresa_turno === this.idTurno,
        )
        this.$emit('getTurno', this.TurnoSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
