<template>
  <b-modal
    id="modal-empresa"
    ref="modal-empresa"
    v-model="vModalState"
    :title="modalTitle"
    size="xl"
    centered
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    @cancel="closeModal"
    @close="closeModal"
  >
    <slot />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {

  components: {
    BModal,
  },
  props: {
    modalState: {
      type: Boolean,
      default: false,
    },

    modalTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      vModalState: false,
    }
  },

  watch: {
    modalState: {
      immediate: true,
      handler() {
        this.vModalState = this.modalState
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
