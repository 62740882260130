import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'

export const cadastrarEmpresaMatriz = form => new Promise((resolve, reject) => {
  axiosIns
    .post(rotasAPI.cadastroEmpresaMatriz, form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const cadastrarEmpresaFilial = form => new Promise((resolve, reject) => {
  axiosIns
    .post(rotasAPI.cadastroEmpresaFilial, form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const editarEmpresaMatriz = (id, form) => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.edicaoEmpresaMatriz(id), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const editarEmpresaFilial = (id, form) => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.edicaoEmpresaFilial(id), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
