<template>
    <validation-observer ref="simpleRules">
        <validation-provider #default="{ errors }">
            <b-form-group
                :state="errors.length > 0 ? false : null"
                :label="labelPadrao"
                label-for="FeedbackPersonalizado"
                class="mt-1 font-medium-1"
            >
                <b-form-textarea
                    id="FeedbackPersonalizado"
                    placeholder=""
                    rows="8"
                    disabled
                    v-model="mensagemSelecionada.mensagem_padrao"
                />
            </b-form-group>
            <b-form-group
                :state="errors.length > 0 ? false : null"
                :label="labelPersonalizado"
                label-for="FeedbackPersonalizado"
                class="mt-1 font-medium-1"
            >
                <div class="text-italic font-medium-1 font-weight-bold" v-text="'* Todas as TAGs HTML, com exceção do <br/>, serão removidas'" />
                <b-form-textarea
                    id="FeedbackPersonalizado"
                    placeholder=""
                    rows="8"
                    v-model="mensagemSelecionada.personalizada"
                />
            </b-form-group>
            <b-col md="12" align="center">
                <div v-if="!loading">
                    <b-button type="reset" variant="outline-secondary" class="mr-1" @click.prevent="fecharModal">
                        Cancelar
                    </b-button>
                    <b-button type="submit" variant="custom" @click.prevent="salvar">
                        Salvar
                    </b-button>
                </div>
                <div v-else>
                    <b-button variant="outline-secondary" class="mr-1 disabled">
                        Cancelar
                    </b-button>
                    <b-button variant="custom" class="disabled">
                        Aguarde, estamos processando sua requisição
                    </b-button>
                </div>
            </b-col>
        </validation-provider>
    </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { modalGenericModel } from '@/libs/sweetalerts';
import {
    BForm,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BOverlay,
    BFormTextarea,
    BFormGroup,
} from "bootstrap-vue";
export default {
    components: {
        BForm,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BRow,

        BCol,
        BFormInput,
        BOverlay,
        BFormTextarea,
        BFormGroup,
    },
    props: {
        grupo: {
            type: Object,
            required: true,
        },
        mensagemSelecionada: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            labelPersonalizado: '',
            labelPadrao: '',
        }
    },
    mounted() {
        if(this.mensagemSelecionada.tipo==='mensagem') {
            this.labelPadrao = 'Mensagem padrão';
            this.labelPersonalizado = 'Mensagem personalizada';
        }else{
            this.labelPadrao = 'Questão padrão';
            this.labelPersonalizado = 'Questão personalizada';
        }

    },
    methods: {
        fecharModal() {
            this.$emit("closeModal");
        },
        AtualizarDados() {
            this.loading = true;
            let rota = "";
            let form = {};
            if (this.mensagemSelecionada.tipo == "mensagem") {
                form = {
                    descricao: this.mensagemSelecionada.personalizada,
                };
                rota = this.$helpers.preparaRotaComMultiplosParametros(
                    this.$api.UpdateMensagem,
                    {
                        "{idEmpresaGrupo}": this.$props.grupo.id_empresa_grupo,
                        "{idMensagem}": this.mensagemSelecionada.id_mensagem,
                    }
                );
            } else {
                form = {
                    conteudo: this.mensagemSelecionada.personalizada,
                };
                rota = this.$helpers.preparaRotaComMultiplosParametros(
                    this.$api.UpdateQuestao,
                    {
                        "{idEmpresaGrupo}": this.$props.grupo.id_empresa_grupo,
                        "{idQuestao}": this.mensagemSelecionada.id_mensagem,
                    }
                );
            }
            this.$http
                .put(rota, form)
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        modalGenericModel(
                            'Dados salvos com sucesso',
                            '',
                            require('@/assets/custom-icons/cora-icons/success.png'),
                            'Ok'
                        ).then(() => {
                            this.fecharModal();
                            this.$nextTick(() => {
                                const element = document.getElementById('FeedbackPersonalizado');
                                if (element) {
                                    element.focus();
                                }
                            });
                        });
                    }
                })
                .catch((error) => {
                    let erros = error.response.data.error ?? error.response.data.errors;
                    this.loading = false;
                    this.handleModalErro(erros);
                });
        },
        handleModalErro(texto) {
            let erros = Object.values(texto);
            let errosDisplay = [];

            erros.map((item) => {
                errosDisplay.push("<div>" + item + "</div>");
            });

            this.$swal({
                title: "Erro ao Salvar",
                html: errosDisplay,

                icon: "error",

                confirmButtonText: "Ok",
            });
        },
        salvar() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.AtualizarDados();
                }
            });
        },
    },
};
</script>