<template>

    <section class="p-2 card">
      <alert-message
        v-if="showAlert"
        :title="titleAlert"
        :message="messageAlert"
        :variant="variantAlert"
      />

      <h1 v-if="useTitle" class="mb-2 mt-2">
        {{ title }}
        <feather-icon
          v-b-popover.hover.right="popoverInfo"
          class="info"
          icon="InfoIcon"
        />
      </h1>

      <b-overlay
        class="py-2"
        :show="loading"
        rounded="lg"
        opacity="0.8"
      >
        <validation-observer
          ref="formEmpresa"
        >
          <b-row>
            <b-col sm="12" lg="4">
              <b-form>
                <b-form-group label="CNPJ*" label-for="cnpj">
                  <div
                    v-if="getMode === 'insert'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CNPJ"
                      rules="required|max:18"
                    >
                      <b-form-input
                        id="cnpj"
                        v-model="getFormData.cnpj"
                        v-mask="'##.###.###/####-##'"
                        :disabled="disabledCnpj"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        @input="formSubmitCnpj"
                      />
                      <div>
                        <small class="text-danger">{{
                          errors[0]
                        }}</small>
                      </div>
                      <div>
                        <small class="text-danger">{{
                          cnpjError
                        }}</small>
                      </div>
                    </validation-provider>
                  </div>

                  <div
                    v-else
                  >
                  <fake-input
                    id="cnpj"
                    bg-color="#efefef"
                    :content="getFormData.cnpj | VMask('##.###.###/####-##')"
                  />
                  </div>
                </b-form-group>
              </b-form>
            </b-col>

            <b-col sm="12" lg="8">
              <b-form>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group label="Razão Social*" label-for="razao">
                      <validation-provider
                        #default="{ errors }"
                        name="Razão Social"
                        rules="required"
                      >
                        <b-form-input
                          id="razao"
                          v-model="getFormData.razao"
                          :disabled="disableElements"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="off"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" lg="6">
                    <b-form-group label="Nome Fantasia*" label-for="nomeFantasia">
                      <validation-provider
                        #default="{ errors }"
                        name="Nome Fantasia"
                        rules="required"
                      >
                        <b-form-input
                          id="nomeFantasia"
                          v-model="getFormData.nomeFantasia"
                          :disabled="disableElements"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="off"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>

          <b-form>
            <b-row>
              <b-col sm="12" lg="4">
                <b-form-group label="Regionais SESI" label-for="regionais">
                  <validation-provider
                    #default="{ errors }"
                    name="Regionais SESI"
                    rules="required"
                  >
                    <v-select
                      id="regionais"
                      v-model="getFormData.regionais"
                      :disabled="disableElements"
                      :options="regioesSelect"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_regiao"
                      placeholder="Selecione uma ou mais"
                      label="descricao"
                      multiple
                      multiselect
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="getCompanyType === 1" sm="12" lg="4">
                <b-form-group label="Serviços Contratados" label-for="modulos">
                  <validation-provider
                    #default="{ errors }"
                    name="Serviços Contratados"
                    rules="required"
                  >
                    <v-select
                      id="modulos"
                      v-model="getFormData.modulos"
                      :disabled="disableElements"
                      :options="modulosSelect"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_modulo"
                      placeholder="Selecione um ou mais"
                      label="descricao"
                      multiple
                      multiselect
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="4">
                <validation-provider
                  #default="{ errors }"
                  name="CEP"
                  rules="required|length:8"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="CEP*"
                    label-for="cep"
                  >
                    <cleave
                      id="cep"
                      value=""
                      v-model="getFormData.cep"
                      :disabled="disableElements"
                      @input="buscarEnderecoPorCep"
                      class="form-control"
                      :options="mask.cep"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    />
                    <small
                      v-if="errors.length > 0 && getFormData.cep.length == 0"
                      class="text-danger"
                      >CEP Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col sm="12" lg="4">
                <b-form-group label="Estado*" label-for="uf">
                  <validation-provider name="Estado">
                    <v-select
                      id="uf"
                      v-model="getFormData.estado"
                      :disabled="disableElements"
                      :options="estados"
                      variant="custom"
                      item-text="descricao"
                      item-value="uf"
                      placeholder="Selecione o Estado"
                      label="descricao"
                      @input="listarCidadesPorUF"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12" lg="4">
                <b-form-group label="Cidade*" label-for="cidade">
                  <validation-provider
                    #default="{ errors }"
                    name="Cidade"
                    rules="required"
                  >
                    <v-select
                      id="cidade"
                      v-model="getFormData.cidade"
                      :disabled="disableElements || !getFormData.estado"
                      :options="cidadesSelect"
                      variant="custom"
                      item-text="nome"
                      item-value="id_cidade"
                      placeholder="Selecione a Cidade"
                      label="nome"
                    />
                    <div><small>{{
                      !getFormData.estado ? "Selecione primeiro o Estado" : ""
                    }}</small></div>
                    <div><small class="text-danger">{{ errors[0] }}</small></div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="4">
                <responsaveis-select
                  v-if="getMode === 'update'"
                  :values="getFormData.responsavel"
                  :empresa="getFormData"
                  @setResponsavel="setResponsavel"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-row>
          <b-col cols="12">
            <div
              v-if="getMode === 'insert' && getCompanyType === 1"
              class="w-100 d-flex justify-content-center my-2"
            >
              <b-button
                type="submit"
                variant="custom"
                :disabled="disableElements"
                class="mr-1"
                @click="formSubmit(false)"
              >
                Salvar e Cadastrar Nova Empresa
              </b-button>
              <b-button
                type="reset"
                :disabled="disableElements"
                variant="custom"
                @click="formSubmit(true)"
              >
                Salvar e Adicionar Filial
              </b-button>
            </div>

            <div v-else class="w-100 d-flex justify-content-center my-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mr-1"
                @click="limparForm"
              >
                Desfazer
              </b-button>
              <b-button
                type="reset"
                :disabled="disableElements"
                variant="custom"
                @click="formSubmit()"
              >
                Salvar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
  BButton,
  VBPopover,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import { estados } from "@/@core/utils/estados";
import { modalGenericModel } from "@/libs/sweetalerts";
import AlertMessage from "@/views/pages/empresas/components/AlertMessage.vue";
import ResponsaveisSelect from "@/views/components/custom/responsaveis/ResponsaveisSelect.vue";
import FakeInput from "@/views/components/custom/inputs/FakeInput.vue";
import Ripple from "vue-ripple-directive";
import {
  cadastrarEmpresaFilial,
  cadastrarEmpresaMatriz,
  editarEmpresaFilial,
  editarEmpresaMatriz,
} from "@/views/pages/empresas/requests";
import {
  getPayloadCadastrarEmpresaFilial,
  getPayloadCadastrarEmpresaMatriz,
  getPayloadEditarEmpresaFilial,
  getPayloadEditarEmpresaMatriz,
} from "@/views/pages/empresas/utils";
import { strClear } from "@core/helpers";
import Cleave from "vue-cleave-component";
import {
  validatorCNPJ
} from '@/@core/utils/validations/validators';
import { MODULOS } from "@/custom-enum/modulos-enum.js";
export default {
  directives: {
    "b-popover": VBPopover,
    ripple: Ripple,
  },

  components: {
    FakeInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    AlertMessage,
    ResponsaveisSelect,
    Cleave
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "insert",
    },
    companyType: {
      type: Number,
      default: 1,
    },
    useTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    popoverInfo: {
      type: String,
      default: "",
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      required,

      estados,

      cnpjError: "",

      loading: true,
      disableElements: this.disable,
      disabledCnpj: false,
      redirectFiliais: false,

      showAlert: false,
      titleAlert: "",
      messageAlert: "",
      variantAlert: "success",

      regioesSelect: [],
      modulosSelect: [],
      cidadesSelect: [],

      getResponsaveis: null,

      mask: {
        cep: {
          delimiters: ["-"],
          blocks: [5, 3],
          numericOnly: true,
          state: false,
        },
      },
    };
  },

  computed: {
    getFormData() {
      return this.formData;
    },

    getMode() {
      return this.mode;
    },

    getCompanyType() {
      return this.companyType;
    },
  },

  watch: {
    getFormData: {
      handler(value) {
        if (!value.cnpj) {
          this.cnpjError = "";
        }
      },
      deep: true,
    },

    'getFormData.modulos'(newVal, oldVal) {
      if (newVal && oldVal) {
        this.verificaModulo(newVal, oldVal);
      }
    },
  },

  mounted() {
    this.dadosSelect();
  },

  methods: {
    verificaModulo(newVal, oldVal){
      if((this.getMode === "update" && oldVal.length > 0) || this.getMode === "insert"){
         if (
          newVal.some(item => item.id_modulo === MODULOS.SAUDE_MENTAL) &&
          !oldVal.some(item => item.id_modulo === MODULOS.SAUDE_MENTAL)
        ){
          modalGenericModel(
            'Atenção!',
            'Para selecionar quais questionários utilizar é necessário acessar a empresa, navegar até a aba "Módulos" e clicar em "Configurações" do módulo Saúde Mental.',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          )
        }
      }
    },
    async dadosSelect() {
      this.loading = true;

      await this.retornaRegioes();
      await this.retornaModulos();

      if (this.getMode === "update") {
        await this.listarCidadesPorUF();
      }

      this.loading = false;
    },

    async retornaRegioes() {
      await this.$http.get(this.$api.RegionaisBusca).then((res) => {
        this.regioesSelect = res.data;
      });
    },

    async retornaModulos() {
      await this.$http.get(this.$api.ModulosBusca).then((res) => {
        this.modulosSelect = res.data;
      });
    },

    async validateForm(formRef) {
      return new Promise((resolve, reject) => {
        this.$refs[formRef]
          .validate()
          .then((success) => {
            if (success) {
              resolve(true);
            }
          })
          .catch(() => {
            reject();
          });
      });
    },

    async formSubmitCnpj() {
      this.cnpjError = "";
      await setTimeout(async () => {
        if (this.getFormData.cnpj.length === 18) {
          if(validatorCNPJ(this.getFormData.cnpj)){
            await this.verificarCnpjEmpresa();
          }else{
            this.cnpjError = "CNPJ inválido";
          }
        }else{
          this.cnpjError = "O campo CNPJ deve ser preenchido corretamente";
        }
      }, 800)
    },

    async formSubmit(redirect) {
      this.redirectFiliais = redirect;
      const resultForm = await this.validateForm("formEmpresa");
      if (resultForm && this.cnpjError === "") {
        this.loading = true;
        await this.core().then(() => {
          this.loading = false;
        });
      }
    },

    async core() {
      if (this.getMode === "insert") {
        await this.cadastrarEmpresa();
      }

      if (this.getMode === "update") {
        await this.editarDadosEmpresa();
      }
    },

    async cadastrarEmpresa() {


      if (this.getCompanyType === 1) {
        const formData = getPayloadCadastrarEmpresaMatriz(this.getFormData);

        await cadastrarEmpresaMatriz(formData)
          .then((response) => {
            if (response.status === 201) {
              this.limparForm();

              this.showAlert = true;
              this.titleAlert = "Nova empresa cadastrada com sucesso!";
              this.messageAlert =
                'Para visualizar suas informações vá em "Empresas" > "Buscar".'

              if (this.redirectFiliais) {
                this.$router.push({
                  name: "filiais",
                  params: {
                    idGrupo: response.data.idEmpresaGrupo,
                    idEmpresa: response.data.idEmpresa,
                  },
                });
              }
            }
          })
          .catch((error) => {
            this.handleError(error.response);
          });
      }

      if (this.getCompanyType === 2) {
        const formData = getPayloadCadastrarEmpresaFilial(this.getFormData);

        await cadastrarEmpresaFilial(formData)
          .then((response) => {
            if (response.status === 201) {
              this.limparForm();
              modalGenericModel(
                'Sucesso!',
                'Dados salvos com sucesso.',
                require('@/assets/custom-icons/cora-icons/success.png'),
                'Ok'
              )
            }
          })
          .catch((error) => {
            this.handleError(error.response);
          });
      }
    },

    async editarDadosEmpresa() {
      const { idEmpresa } = this.getFormData;

      let formData;
      let editarEmpresaFn;

      if (this.getCompanyType === 1) {
        formData = getPayloadEditarEmpresaMatriz(this.getFormData);
        editarEmpresaFn = editarEmpresaMatriz;
      } else if (this.getCompanyType === 2) {
        formData = getPayloadEditarEmpresaFilial(this.getFormData);
        editarEmpresaFn = editarEmpresaFilial;
      }

      if (formData && editarEmpresaFn) {
        try {
          const response = await editarEmpresaFn(idEmpresa, formData);

          if (response.status === 200) {
            this.limparForm();
            modalGenericModel(
              'Sucesso!',
              'Dados salvos com sucesso.',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
          }
        } catch (error) {
          this.handleError(error.response);
        }
      }
    },
    handleError(response) {
      const errors = response.status === 400 || response.status === 404;

      if (errors) {
        return this.modalWarningGeneric(response.data.error.message)
      }

      this.modalWarningGeneric(
        "Não foi possível realizar a sua solicitação.<br> Entre em contato com o suporte"
      )
    },

    async verificarCnpjEmpresa() {
      this.loading = true;
      this.disabledCnpj = true;
      this.disableElements = true;

      this.cnpjError = "";
      let cnpjPayload = this.getFormData.cnpj;

      cnpjPayload = strClear(cnpjPayload);

      await this.$http
        .get(this.$api.EmpresasValidarCnpj(cnpjPayload))
        .then((res) => {
          if (res.status === 204) {
            this.disableElements = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.disabledCnpj = false;
            this.disableElements = true;

            this.cnpjError = "CNPJ já consta em nosso sistema";
          }
        });
      this.loading = false;
      this.disableElements = false;
    },

    async buscarEnderecoPorCep() {
      if (this.getFormData.cep.length === 8) {
        this.loading = true;
        this.disableElements = true;
        let cepPayload = this.getFormData.cep;

        cepPayload = strClear(cepPayload);

        await this.$http
          .get(this.$api.BuscaEnderecoCep(cepPayload))
          .then((response) => {
            const res = response.data;

            this.cidadesSelect = res.cidadesUF;

            this.estados.forEach((obj) => {
              if (obj.uf === res.cidade.uf) {
                this.getFormData.estado = obj;
              }
            });

            this.getFormData.cidade = res.cidade;
          })
          .catch((error) => {
            if (this.getMode === "update") {
              this.listarCidadesPorUF();
            }

            this.limparDadosCep();

            if (error.response.status === 404) {
              this.modalWarningGeneric(
                error.response.data.error
              )
            } else {
              this.modalWarningGeneric(
                "Não foi possível realizar a sua solicitação"
              )
            }
          });
        this.disableElements = false;
        this.loading = false;
      }
    },

    async listarCidadesPorUF() {
      if (this.getFormData.estado) {
        await this.$http
          .get(this.$api.BuscaCidadesUF(this.getFormData.estado.uf))
          .then((response) => {
            if (response.status === 200) {
              this.cidadesSelect = response.data;
            }
          });
      } else {
        this.limparDadosCep();
      }
    },

    limparDadosCep() {
      if (this.getMode === "insert") {
        this.getFormData.cidade = null;
        this.getFormData.estado = null;
      }
    },

    limparForm() {
      this.getFormData.estado = null;

      this.$emit("clearForm");

      this.disableElements = true;
      this.disabledCnpj = false;

      this.$refs.formEmpresa.reset();
    },

    setResponsavel(responsavel) {
      this.getFormData.responsavel = responsavel;
    },

    modalWarningGeneric(message){
      modalGenericModel(
        message,
        '',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Ok'
      )
    }
  },
};
</script>

