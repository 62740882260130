<template>
  <div>
    <h2>Turnos</h2>
    <b-alert
      v-if="verificaRelaciona"
      variant="warning"
      show
      id="sem-responsavel"
      class="alert custom mb-0"
    >
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        <strong class=""
          >Relacione os campos de turno da Empresa de acordo com os padrões do
          SESI
        </strong>
      </div>
    </b-alert>
    <h4>Adicionar novo Turno</h4>
    <b-alert variant="danger" show>
      <div v-if="erros != null" class="alert-body">
        <strong>Registro não efetuado </strong>
        <ul id="array-with-index">
          <li v-for="erro in erros" :key="erro.errors">
            {{ erro }}
          </li>
        </ul>
      </div>
    </b-alert>

    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <validation-observer ref="simpleRules">
        <b-form id="TurnoForm" ref="TurnoForm" @submit.prevent="Salvar">
          <b-row>
            <b-col xl="5" md="5">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label="Turno de acordo com a Empresa"
                  label-for="descricao"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.descricao"
                    id="descricao"
                    name="descricao"
                    :disabled="false"
                    v-on:keyup.enter="$event.target.blur()"


                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >Campo Obrigatório</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col xl="5" md="5">
              <validation-provider #default="{ errors }" :rules="''">
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Selecione o Turno de acordo com o SESI"
                  label-for="Select-turno"
                >
                  <v-select
                    id="Select-turno"
                    v-model="form.turnos"
                    @input="getTurnos"
                    multiple
                    multiselect
                    variant="custom"
                    item-text="descricao"
                    item-value="id_turno"
                    label="descricao"
                    :options="turnos"
                    placeholder="Selecione 1 ou mais"
                  >
                    <span slot="no-options">Nenhum Turno encontrado.</span>
                  </v-select>
                  <small v-if="errors.length > 0" class="text-danger"
                    >Campo Obrigatório</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <div class="botInc">
              <br />
              <b-button :disabled="disableSalvar" type="submit" variant="custom" @click.prevent="Salvar">
                Incluir
              </b-button>
            </div>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>

    <listaTurnos
      :carregar="carregar"
      @showAlertRelaciona="showAlertRelaciona"
      @nRel="numeroNaoRelacionados"
      @geraErro="geraErro"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import listaTurnos from "@/views/components/custom/parametros/turnos/ListaTurnos.vue";
import { modalGenericModel } from '@/libs/sweetalerts'
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BAlert,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCard,
    BRow,
    BButton,
    BAlert,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    BCol,
    listaTurnos
  },
  watch: {
    loadingShow(value) {
      this.disableSalvar = value;
    },
  },

  data() {
    return {
      disableSalvar:false,
      carregar: false,
      loadingShow: false,
      turnos: [],
      verificaRelaciona: false,
      erros: null,
      form: {
        descricao: "",
        turnos: [],
      },
    };
  },
  methods: {
    showAlertRelaciona(value) {
      this.verificaRelaciona = value;
    },
    numeroNaoRelacionados(value) {
      this.$emit("nRel", value);
    },

    FormataDadosFormulario() {
      let turnos = this.$helpers.retornaAtributoArray(
        this.form.turnos,
        "id_turno"
      );

      return {
        id_empresa_grupo: this.$route.params.idGrupo,
        descricao: this.form.descricao,
        turnos: turnos,
      };
    },
    geraErro(value) {
      this.erros = value;
    },
    limpaForm() {
      this.form.descricao = "";
      this.form.turnos = [];
      this.$refs.simpleRules.reset();
    },
    async SalvarDados() {
      let form = this.FormataDadosFormulario("create");
      this.loadingShow = true;
      this.carregar = false;
      this.$refs.simpleRules.reset();
      try {
        await this.$http
          .post(this.$api.CadastroEmpresaTurno, form)
          .then((res) => {
            if (res.status == 201) {
              modalGenericModel(
                'Sucesso!',
                'Salvo com Sucesso',
                require('@/assets/custom-icons/cora-icons/success.png'),
                'Ok'
              ).then(() => {
                this.carregar = true;
                this.limpaForm();
                this.erros = null;
                this.loadingShow = false;
              })
            }
          });
      } catch (error) {
        this.loadingShow = false;

        this.erros = error.response.data.error
          ? error.response.data.error
          : error.response.data.errors;
      }
    },
    buscaTurnos() {
      this.$http.get(this.$api.BuscaParametrosTurno).then((res) => {
        this.turnos = res.data;
      });
    },
    getTurnos(EstadoTurno) {
      this.form.turnos = EstadoTurno;
    },
    Salvar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.SalvarDados();
        }
      });
    }
  },
  created() {
    this.buscaTurnos();
  },
};
</script>

<style scoped>
.alert-body {
  margin-top: 20px;
  margin-bottom: 20px;
}
.botInc {
  margin-left: auto;
  margin-right: 15px;
}
h4 {
  margin-top: 15px;
}
</style>
