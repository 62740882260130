<template>
  <b-table
    id="listCompaniesTable"
    responsive
    sticky-header="380px"
    :busy.sync="tabela.tabelaOcupada"
    :no-local-sorting="true"
    :fields="tabela.fields"
    :items="itensTabela"
    show-empty
    empty-text="Não há registros para mostrar."
    @context-changed="ordenarTabela"
  >
    <template #cell(qtd_filiais)="row">
      <feather-icon
        v-if="row.value > 0"
        icon="DiscIcon"
        size="14"
        class="pointer text-primary"
        @click="
          buscarDadosFiliais(row.item);
          row.toggleDetails();
        "
      />
      <feather-icon
        v-if="row.value == 0 && row.item.tipo_empresa.descricao != 'Filial'"
        icon="CircleIcon"
        size="14"
      />

      <img
        v-if="row.item.tipo_empresa.descricao == 'Filial'"
        src="@/assets/custom-icons/dot.svg"
        alt="icone de filial"
      >
    </template>
    <template #cell(razao)="row">
      <strong
        v-if="row.item.qtd_filiais > 0"
        class="pointer"
        @click="
          buscarDadosFiliais(row.item);
          row.toggleDetails();
        "
      >
        {{ row.value }}
      </strong>
      <strong v-if="row.item.qtd_filiais == 0">{{ row.value }}</strong>
    </template>

    <template #cell(cnpj)="row">
      <span>{{ row.value | VMask("##.###.###/####-##") }}</span>
    </template>

    <template #row-details="row">
      <div
        v-if="!row.item.filiais"
        class="
          w-100
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
      >
        <b-spinner
          variant="custom"
          class="mb-1"
          label="Loading..."
        />
      </div>
      <b-table-simple
        v-if="row.item.filiais"
        hover
        responsive
        style="background-color: #f8f8f8"
      >
        <b-tbody>
          <b-tr
            v-for="filial in row.item.filiais"
            :key="filial.id_empresa"
          >
            <b-td class="width-custom-column">
              <img
                src="@/assets/custom-icons/dot.svg"
                alt="icone de filial"
              >
            </b-td>
            <b-td
              class="width-column-detail-razao"
            ><strong>{{ filial.razao }}</strong></b-td>
            <b-td class="width-column-detail-tipo">
              Filial
            </b-td>
            <b-td class="width-column-detail-cnpj">
              {{ filial.cnpj }}
            </b-td>
            <b-td class="width-column-detail-city">
              {{ filial.cidade }}
            </b-td>
            <b-td class="width-column-detail-state">
              {{ filial.uf }}
            </b-td>
            <b-td class="width-column-detail-person">{{
              filial.qtd_colaboradores
            }}</b-td>
            <b-td class="width-column-detail-action">
              <b-button
                variant="outline-primary"
                class="btn-icon"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </template>

    <template #cell(acoes)="data">
      <b-button
        variant="outline-primary"
        class="btn-icon"
        :disabled="!data.item.ativo"
        @click="eventoBotaoAcao(data.item)"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { BTable, BButton, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BOverlay,
  },
  props: {
    itensTabela: {
      type: Array,
      required: true,
    },
    paginaBuscarEmpresa: {
      type: Boolean,
      required: false,
      default: true,
    },
    grupoModulos: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    grupo: {
      type: Object,
      default() {
        return {}
      },
      required: false,
    },
  },
  data() {
    return {
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: '',
        campoOrdenado: '',
        fields: [],
      },
    }
  },
  beforeMount() {
    const ocultar = this.paginaBuscarEmpresa ? '' : 'd-none'
    const setSortable = this.paginaBuscarEmpresa

    this.tabela.fields = [
      {
        key: 'qtd_filiais',
        label: '',
        class: `width-custom-column ${ocultar}`,
      },
      { key: 'nome_fantasia', label: 'Nome Fantasia', sortable: setSortable },
      { key: 'tipo_empresa.descricao', label: 'tipo', sortable: setSortable },
      { key: 'cnpj', label: 'cnpj' },
      { key: 'cidade.nome', label: 'cidade', sortable: setSortable },
      { key: 'cidade.uf', label: 'estado', sortable: setSortable },
      { key: 'qtd_colaboradores', label: 'pessoa', sortable: setSortable },
      { key: 'acoes', label: 'ações', class: 'width-custom-column' },
    ]

    this.mostrarModalEmpresaFilialDeEmpresaPreSelecionada(
      this.$route.params.idEmpresa,
    )
  },
  methods: {
    ordenarTabela(contextoTabela) {
      this.$emit('ordenar-tabela', {
        campoOrdenado: contextoTabela.sortBy,
        ordem: contextoTabela.sortDesc ? 'desc' : 'asc',
      })
    },
    mostrarModalEmpresaFilial(empresa) {
      this.$emit('mostrarModalAtualizarFilial', empresa)
    },
    mostrarModalEmpresaFilialDeEmpresaPreSelecionada(idEmpresa) {
      if (this.$helpers.variavelExiste(idEmpresa)) {
        const empresa = this.itensTabela.find(
          item => item.id_empresa === idEmpresa,
        )

        if (this.$helpers.variavelExiste(empresa)) {
          this.$route.params.idEmpresa = null
          this.mostrarModalEmpresaFilial(empresa)
        }
      }
    },
    eventoBotaoAcao(empresa) {
      if (!this.paginaBuscarEmpresa) {
        this.mostrarModalEmpresaFilial(empresa)
      }
    },
  },
}
</script>
