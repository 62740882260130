<template>
  <section class="tabela-Pessoas">
    <b-row v-if="dadosPaginacao.totalLinhas > 0">
      <b-col sm="12">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <b-col sm="4" class="pl-0">
            <label class="mr-50">Visualizar</label>
            <b-form-select
              id="amounlinesVisualizer"
              v-model="dadosPaginacao.quantidadeLinhasPadrao"
              size="sm"
              class="w-50"
              label="option"
              :options="dadosPaginacao.pageOptions"
            />
          </b-col>
          <div
            class="col-auto pr-0"
            v-b-tooltip.hover.top="
              loteColaboradoresAlteraLider.length === 0
                ? 'É necessário selecionar ao menos uma pessoa'
                : ''
            "
          >
            <b-button
              v-show="this.consultaFeitaComFiltroLider"
              variant="outline-primary"
              :disabled="loteColaboradoresAlteraLider.length === 0"
              @click="eventoBotaoAlteraLider(loteColaboradoresAlteraLider)"
            >
              Alterar Lider
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12" class="my-1">
        <b-table
          v-if="dadosPaginacao.totalLinhas > 0"
          id="listPessoasTable"
          responsive="sm"
          no-border-collapse
          :per-page="dadosPaginacao.quantidadeLinhasPadrao"
          :current-page="dadosPaginacao.paginaAtual"
          sticky-header="100%"
          :busy.sync="tabela.tabelaOcupada"
          :no-local-sorting="false"
          :fields="tabela.fields"
          :items="itensTabela"
          empty-text="Não há registros para mostrar."
        >
          <template #head(id_colaborador)>
            <b-form-checkbox
              v-model="selecionarTodos"
              class="p-0"
              @change="marcarOuDesmarcarTodos()"
            ></b-form-checkbox>
          </template>
          <template #cell(id_colaborador)="data"
            >
            <b-form-checkbox
              v-model="loteColaboradoresAlteraLider"
              :value="data.item.id_colaborador"
              class="p-0"
            />
          </template>
          <template #cell(email)="data">
            {{ verificaEmail(data.item.email) }}
          </template>
          <template #cell(perfil)="data">
            {{ verificaTrabalhador(limpar(data.item.perfil)) }}
          </template>
          <template #cell(acoes)="data">
            <div
              v-if='$can("ATUALIZAR", "PORTAL_GRUPO_PESSOAS")'
            >
              <b-button
                variant="outline-primary"
                class="btn-icon"
                @click="eventoBotaoAcao(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
            <div
              v-else
              class="text-center"
            >
              -
            </div>
          </template>
        </b-table>
        <b-alert
          variant="primary"
          :show="dadosPaginacao.totalLinhas == 0"
          class="mt-5"
        >
          <div class="alert-body d-flex justify-content-center">
            <span class="text-primary">
              <strong class="text-primary">Nenhum registro encontrado.</strong>
              Realize novamente uma busca utilizando diferentes critérios.
            </span>
          </div>
        </b-alert>
      </b-col>

      <b-col cols="12" v-if="dadosPaginacao.totalLinhas > 0">
        <label class="label-amount-table mb-0 d-none d-md-block">
          Mostrando {{ dadosPaginacao.primeiroMostrado }} a
          {{ dadosPaginacao.ultimoMostrado }} de
          {{ dadosPaginacao.totalLinhas }} resultados
        </label>
        <b-pagination
          v-model="dadosPaginacao.paginaAtual"
          :total-rows="dadosPaginacao.totalLinhas"
          :per-page="dadosPaginacao.quantidadeLinhasPadrao"
          align="right"
          size="lg"
          pills
          hide-goto-end-buttons
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BTable,
  BButton,
  BFormSelect,
  BOverlay,
  BPagination,
  BCol,
  BRow,
  BFormGroup,
  BAlert,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";

export default {
  components: {
    BFormCheckbox,
    BTable,
    BButton,
    BOverlay,
    BPagination,
    CustomPagination,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BAlert,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    itensTabela: {
      type: Array,
      required: true,
    },
    paginaBuscarEmpresa: {
      type: Boolean,
      required: false,
      default: true,
    },
    paginaAtual: {
      type: Number,
      default: 1,
    },
    grupoModulos: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    grupo: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    filtroLiderAtivo: {
      type: Boolean,
      default() {
        return false;
      },
      required: false,
    },
  },

  data() {
    return {
      dadosPaginacao: {
        totalLinhas: 0,
        quantidadeLinhasPadrao: 10,
        pageOptions: [10, 25, 50, 100],
        primeiroMostrado: 1,
        ultimoMostrado: 10,
        paginaAtual: 1,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: "",
        campoOrdenado: "",
        fields: [],
      },
      selecionarTodos: false,
      loteColaboradoresAlteraLider: [],
      idsColaboradoresDaPaginaAtual: [],
      consultaFeitaComFiltroLider: false,
    };
  },
  beforeMount() {
    this.handlePermiteAtualizarLider();
    this.mostrarModalEmpresaFilialDeEmpresaPreSelecionada(
      this.$route.params.idEmpresa
    );
  },
  computed: {
    permiteAlterarLiderComputed() {
      return (
        this.filtroLiderAtivo && this.$can("ATUALIZAR", "PORTAL_GRUPO_PESSOAS")
      );
    },
  },
  watch: {
    itensTabela(value) {
      this.dadosPaginacao.totalLinhas = value.length;

      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      } else if (
        this.dadosPaginacao.ultimoMostrado < this.dadosPaginacao.totalLinhas &&
        this.dadosPaginacao.totalLinhas >
          this.dadosPaginacao.quantidadeLinhasPadrao
      ) {
        this.dadosPaginacao.ultimoMostrado =
          this.dadosPaginacao.quantidadeLinhasPadrao;
      } else {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      }

      this.organizaSelecaoDeTrocaDeLiderPorPagina();
    },
    "dadosPaginacao.paginaAtual"() {
      this.dadosPaginacao.ultimoMostrado =
        this.dadosPaginacao.paginaAtual *
        this.dadosPaginacao.quantidadeLinhasPadrao;

      this.dadosPaginacao.primeiroMostrado =
        this.dadosPaginacao.ultimoMostrado -
        this.dadosPaginacao.quantidadeLinhasPadrao +
        1;

      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      }

      this.organizaSelecaoDeTrocaDeLiderPorPagina();
    },
    "dadosPaginacao.quantidadeLinhasPadrao"(value) {
      this.dadosPaginacao.ultimoMostrado = value;
      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      }

      this.organizaSelecaoDeTrocaDeLiderPorPagina();
    },
    "$store.state.sessions.userData.ability": function () {
      this.handlePermiteAtualizarLider();
    },
  },
  methods: {
    verificaEmail(item) {
      if (item == null) {
        return "Não informado";
      } else {
        return item;
      }
    },
    limpar(item) {
      item = item.replaceAll("{", "");
      item = item.replaceAll("}", "");
      item = item.replaceAll(",NULL", "");
      item = item.replaceAll("NULL", "");
      item = item.replaceAll(",", ", ");

      return item;
    },
    verificaTrabalhador(item) {
      if (item == "") {
        return "Trabalhador";
      } else {
        return item;
      }
    },
    mostrarModalEmpresaFilial(empresa) {
      this.$store.commit("modal/UPDATE_EMPRESA_MODAL_FORMULARIO_VALORES", {
        Titulo: `Editar - Filial: ${empresa.razao}`,
        Empresa: empresa,
        Modulos: this.grupoModulos,
        Regionais: empresa.regionais,
        EmpresaTipo: 2,
        Grupo: this.grupo,
      });
    },
    mostrarModalEmpresaFilialDeEmpresaPreSelecionada(idEmpresa) {
      if (this.$helpers.variavelExiste(idEmpresa)) {
        let empresa = this.itensTabela.find(
          (item) => item.id_empresa == idEmpresa
        );

        if (this.$helpers.variavelExiste(empresa)) {
          this.mostrarModalEmpresaFilial(empresa);
        }
      }
    },
    eventoBotaoAcao(pessoa) {
      this.$emit("showModal", pessoa);
    },
    eventoBotaoAlteraLider(lote) {
      this.$emit("showModalEditarLider", lote);
    },
    organizaSelecaoDeTrocaDeLiderPorPagina() {
      // reinicia array de ids dos colaboradores visíveis na página atual
      this.idsColaboradoresDaPaginaAtual = this.itensTabela
        .slice(
          this.dadosPaginacao.primeiroMostrado - 1,
          this.dadosPaginacao.ultimoMostrado
        )
        .map((colab) => colab.id_colaborador);

      // reinicia as caixas de seleção
      this.loteColaboradoresAlteraLider = [];
      if (this.loteColaboradoresAlteraLider.length === 0) {
        this.selecionarTodos = false;
      }
      this.handlePermiteAtualizarLider();
      this.consultaFeitaComFiltroLider = this.permiteAlterarLiderComputed;
    },
    marcarOuDesmarcarTodos() {
      this.loteColaboradoresAlteraLider = this.selecionarTodos
        ? this.idsColaboradoresDaPaginaAtual
        : [];
    },
    handlePermiteAtualizarLider() {
      this.tabela.fields = [
        this.permiteAlterarLiderComputed
          ? { key: "id_colaborador", label: "#", class: "text-center" }
          : {},
        { key: "nome", label: "Nome", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "empresa", label: "Empresa", sortable: true },
        { key: "unidade", label: "unidade", sortable: true },
        { key: "perfil", label: "perfil", sortable: true },
        { key: "lider", label: "líder", sortable: true },
        { key: "acoes", label: "ações", class: "width-custom-column" }
      ];
    },
  },
};
</script>

