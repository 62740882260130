<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Funcao"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Funcao"
            v-model="FuncaoSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_empresa_funcao"
            label="descricao"
            :options="Funcao"
            placeholder="Funcao"
            :disabled="Disabled"
            @input="SelecionaFuncao"
          >
            <span slot="no-options">Nenhuma Função encontrada.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BOverlay
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idFuncao: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      FuncaoSelecionado: '',
      Funcao: [],
      NomeLabel: 'Função',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_empresa_funcao) {
        this.FuncaoSelecionado = value
        return;
      }
      this.FuncaoSelecionado = null
    },
    idFuncao(value) {
      this.FuncaoSelecionado = this.Funcao.filter(
        x => x.id_empresa_funcao === value,
      )
      this.$emit('getFuncao', this.FuncaoSelecionado[0])
    },
    disabledProp(value) {
      this.Disabled = value
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaFuncao()
  },
  methods: {
    SelecionaFuncao() {
      this.$emit('getFuncao', this.FuncaoSelecionado)
    },
    async buscaFuncao() {
      this.loading = true

      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.FuncaoBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      await this.$http.get(rota).then(res => {
        this.Funcao = res.data
        this.FuncaoSelecionado = this.Funcao.filter(
          x => x.id_empresa_funcao === this.idFuncao,
        )
        this.$emit('getFuncao', this.FuncaoSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
