<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Empresa"
      >
        <v-select
          id="Select-Empresa"
          v-model="EmpresaSelecionado"
          @input="SelecionaEmpresa"
          variant="custom"
          item-text="nome_fantasia"
          item-value="id_empresa"
          label="nome_fantasia"
          :options="Empresa"
          placeholder="Empresa"
          :clearable="false"
          :disabled="Disabled"
        >
          <span slot="no-options">Nenhuma Empresa encontrada.</span>
        </v-select>
        <small v-if="errors.length > 0" class="text-danger"
          >Campo Obrigatório</small
        >
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";

import { BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idEmpresa: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      EmpresaSelecionado: "",
      Empresa: [],
      NomeLabel: "Empresa",
      Disabled: false,
    };
  },
  methods: {
    SelecionaEmpresa() {
      this.$emit("getEmpresa", this.EmpresaSelecionado);
    },
    buscaEmpresa() {
      this.$http.get(this.$api.EmpresasBusca,
        { params: { id_empresa_grupo: this.$route.params.idGrupo } })
        .then((res) => {
        this.Empresa = res.data;
        this.EmpresaSelecionado = this.Empresa.filter(
          (x) => x.id_empresa === this.idEmpresa
        );
        this.$emit("getEmpresa", this.EmpresaSelecionado[0]);
      });
    },
  },
  watch: {
    values(value) {
      if (value?.id_empresa) {
        this.EmpresaSelecionado = value;
        return;
      }
      this.EmpresaSelecionado = null;
    },
    idEmpresa(value) {
      this.EmpresaSelecionado = this.Empresa.filter(
        (x) => x.id_empresa === value
      );
      this.$emit("getEmpresa", this.EmpresaSelecionado[0]);
    },
    disabledProp(value) {
      this.Disabled = value;
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += " *";
    }
    this.buscaEmpresa();
  },
};
</script>
