<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Setor"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Setor"
            v-model="SetorSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_empresa_setor"
            label="descricao"
            :options="Setor"
            placeholder="Setor"
            :disabled="Disabled"
            @input="SelecionaSetor"
          >
            <span slot="no-options">Nenhum Setor encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BOverlay,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idSetor: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      SetorSelecionado: '',
      Setor: [],
      NomeLabel: 'Setor',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_empresa_setor) {
        this.SetorSelecionado = value
        return;
      }
      this.SetorSelecionado = null
    },
    idSetor(value) {
      this.SetorSelecionado = this.Setor.filter(
        x => x.id_empresa_setor === value,
      )
      this.$emit('getSetor', this.SetorSelecionado[0])
    },
    disabledProp(value) {
      this.Disabled = value
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaSetor()
  },
  methods: {
    SelecionaSetor() {
      this.$emit('getSetor', this.SetorSelecionado)
    },
    async buscaSetor() {
      this.loading = true

      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.SetorBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      await this.$http.get(rota).then(res => {
        this.Setor = res.data
        this.SetorSelecionado = this.Setor.filter(
          x => x.id_empresa_setor === this.idSetor,
        )
        this.$emit('getSetor', this.SetorSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
