<template>
  <Form
    :form-data="formData"
    :company-type="getCompanyType"
    :use-title="useTitle"
    :title="title"
    :popover-info="popoverInfo"
    mode="insert"
    @clearForm="limparForm"
  />
</template>

<script>
import Form from '@/views/pages/empresas/Form.vue'

export default {
  components: {
    Form,
  },

  props: {
    idEmpresaGrupo: {
      type: Number,
      default: null,
    },
    className: {
      type: String,
      default: '',
    },
    useTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Cadastrar Nova Empresa Matriz',
    },
    popoverInfo: {
      type: String,
      default: 'Cadastro de uma empresa matriz ou conjunto de matriz com filiais.',
    },
  },

  data() {
    return {
      formData: {
        cnpj: '',
        razao: '',
        nomeFantasia: '',
        cep: '',
        idEmpresaGrupo: this.idEmpresaGrupo,
        estado: null,
        cidade: null,
        modulos: [],
        regionais: [],
      },
    }
  },

  computed: {
    getCompanyType() {
      return this.$store.state.modal.tipoEmpresa
    },
  },

  methods: {
    limparForm() {
      this.formData = {
        cnpj: '',
        razao: '',
        nomeFantasia: '',
        cep: '',
        estado: null,
        cidade: null,
        idEmpresaGrupo: this.idEmpresaGrupo,
        modulos: [],
        regionais: [],
      }

      this.$emit('clearForm')
    },
  },
}
</script>

