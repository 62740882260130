<template>
  <div>
    <b-row>
      <b-col md="1" xl="12" class="busca">
        <b-form-group>
          <b-input-group v-on:keyup.enter="carregarDados(1)">
            <template #append>
              <b-input-group-text>
                <feather-icon v-on:click="carregarDados(1)" icon="SearchIcon" />
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="textoBusca"
              id="busca"
              name="busca"
              placeholder="Buscar Gerência"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay :show="loadingLista" rounded="lg" opacity="1">
      <div v-if="is_data_fetched">
        <div
          v-on:dblclick="editarRegistro(item.id_empresa_gerencia)"
          id="lista"
          v-for="(item, id) in this.empresaGerencias"
          :key="id"
        >
          <validation-observer ref="simpleRules">
            <b-form
              id="EmpresaForm"
              ref="EmpresaForm"
              @submit.prevent="salvarRegistro(item)"
            >
              <b-row>
                <b-col md="1" xl="9">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-group :state="errors.length > 0 ? false : true">
                      <b-form-input
                        v-model="item.descricao"
                        :disabled="!editGerencias[item.id_empresa_gerencia]"
                        :id="item.descricao"
                        :name="item.descricao"
                        placeholder="Gerência descrição"
                        v-on:keyup.enter="salvarRegistro(item)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col xl="1" md="1"> </b-col>

                <div class="DivBotoes">
                  <b-button
                    v-show="!editGerencias[item.id_empresa_gerencia]"
                    @click.prevent="editarRegistro(item.id_empresa_gerencia)"
                    variant="outline-primary"
                    class="btn-icon bt"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-show="editGerencias[item.id_empresa_gerencia]"
                    @click.prevent="salvarRegistro(item)"
                    variant="outline-primary"
                    class="btn-icon bt"
                  >
                    ok
                  </b-button>

                  <b-button
                    @click.prevent="deletarRegistro(item.id_empresa_gerencia)"
                    variant="outline-primary"
                    class="btn-icon bt"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
        <b-col sm="12">
          <CustomPagination
            :paginacao="dadosPaginacao"
            @page-cliked="atualizarPaginaAtual"
          />
        </b-col>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import { modalGenericModel } from '@/libs/sweetalerts'
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BAlert,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupText,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCard,
    BInputGroup,
    BRow,
    BButton,
    BAlert,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroupText,
    BOverlay,
    BCol,
    CustomPagination
  },

  data() {
    return {
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 5,
      },
      loadingLista: false,
      erros: null,
      textoBusca: "",
      empresaGerencias: [],
      editGerencias: [],
      aux: [],
      is_data_fetched: false
    };
  },

  props: {
    carregar: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.carregarDados(1);
  },

  methods: {
    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from;
      this.dadosPaginacao.ateALinha = dadosPaginacao.to;
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page;
      this.carregarDados(page);
    },

    async carregarDados(page = 1) {
      this.loadingLista = true;
      this.BuscaEmpresaGerencias(page);
    },

    editarRegistro(value) {
      this.$set(this.editGerencias, value, true);
    },

    async salvarRegistro(value) {
      let form = this.FormataDadosFormulario(value);

      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.UpdateEmpresaGerencia,
        "{idEmpresaGerencia}",
        value.id_empresa_gerencia
      );

      try {
        await this.$http.put(rota, form).then((res) => {
          if (res.status == 200) {
            modalGenericModel(
              'Sucesso!',
              'Salvo com Sucesso',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            ).then(() => {
              this.$set(this.editGerencias, value.id_empresa_gerencia, false);
              this.erros = null;
            })
          } else {
            this.erros = error.response.data.error
              ? error.response.data.error
              : error.response.data.errors;
          }
        });
      } catch (error) {
        this.erros = error.response.data.error
          ? error.response.data.error
          : error.response.data.errors;
      }
    },

    geraErros(value) {
      this.$emit("geraErro", value);
    },

    async deletarRegistro(value) {
      await modalGenericModel(
        'Tem certeza?',
        'Não será possível desfazer essa ação depois',
        require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
        'Sim, deletar',
        'Cancelar'
      ).then((result) => {
        if(result.isConfirmed){
          this.confirmaDeleta(value)
        }
      })
    },

    FormataDadosFormulario(value) {
      return {
        id_empresa_grupo: this.$route.params.idGrupo,
        descricao: value.descricao,
      };
    },

    async BuscaEmpresaGerencias(page) {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaEmpresaGerencia,
        "{grupo}",
        this.$route.params.idGrupo
      );
      let $param = { busca: this.textoBusca, page: page };
      await this.$http.get(rota, { params: $param }).then((res) => {
        this.empresaGerencias = res.data.data;
        this.IniciarPaginacao(res.data);
        this.gerenciasSelecionados = this.aux;
        this.is_data_fetched = true;
        this.loadingLista = false;
      });
    },

    mostraItens(value) {
      let ret;
      try {
        ret = value.length > 0 ? "mostraItens" : "semItens";
      } catch {
        ret = "semItens";
      }
      return ret;
    },

    async confirmaDeleta(value) {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.DeletaEmpresaGerencia,
        "{idEmpresaGerencia}",
        value
      );
      try {
        await this.$http.delete(rota).then(() => {
          this.carregarDados();
        });
      } catch {
        modalGenericModel(
          'Não foi possivel realizar essa ação',
          'Existem pessoas vinculadas a essa categoria <br />'+
          'Desvincule antes e tente novamente',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        )
      }
    }
  },
  watch: {
    erros(value) {
      this.geraErros(value);
    },
    carregar(value) {
      if (value) {
        this.carregarDados(1);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.busca {
  margin-top: 30px;
}
#lista {
  box-shadow: 0px 0px 6px #d8d6de;
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
}

.bt {
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* 2) Opacity Color/azul 4 */

  background: rgba(18, 101, 187, 0.04);
  /* 1) Theme Color/Info */

  border: 1px solid #2772c0;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin-left: 12px;
}
.itens {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 4px;

  position: static;
  width: fit-content;

  left: 0px;
  top: 3.85%;
  bottom: 3.85%;

  /* 1) Theme Color/Info */

  background: #2772c0;
  border-radius: 3px;

  /* Inside auto layout */
  display: inline-block;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
}

.itens span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 18px;
  left: 4px;
  right: 4px;
  top: 3px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 3px;
}
div.mostraItens {
  display: flex;
  flex-direction: row;
  min-height: 37px;

  padding: 5px;

  border-radius: 5px;
}
div.semItens {
  display: flex;
  flex-direction: row;
  min-height: 37px;

  border: 1px solid #ff7c02;

  padding: 5px;

  border-radius: 5px;
}
.DivBotoes {
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 10px;
}
</style>

