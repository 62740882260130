<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Regime"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Regime"
            v-model="RegimeSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_regime"
            label="descricao"
            :options="Regime"
            placeholder="Regime"
            :disabled="Disabled"
            @input="SelecionaRegime"
          >
            <span slot="no-options">Nenhum Regime encontrado.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BOverlay,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idRegime: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
    modoVisualizacao: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      RegimeSelecionado: '',
      Regime: [],
      NomeLabel: 'Regime',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_regime) {
        this.RegimeSelecionado = value
        return;
      }
      this.RegimeSelecionado = null
    },
    idRegime(value) {
      this.RegimeSelecionado = this.Regime.filter(x => x.id_regime === value)
      this.$emit('getRegime', this.RegimeSelecionado[0])
    },
    disabledProp(value) {
      this.Disabled = value
      if (this.modoVisualizacao) {
        this.Disabled = true
      }
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaRegimeResponsavel()
  },
  methods: {
    SelecionaRegime() {
      this.$emit('getRegime', this.RegimeSelecionado)
    },
    async buscaRegimeResponsavel() {
      this.loading = true

      await this.$http.get(this.$api.RegimeBusca).then(res => {
        this.Regime = res.data
        this.RegimeSelecionado = this.Regime.filter(
          x => x.id_regime === this.idRegime,
        )
        this.$emit('getRegime', this.RegimeSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
