<template>
    <b-overlay :show="loading" rounded="lg" opacity="0.6">
        <div
            class="scrollable"
            ref="meuModal"
            id="GrupoModulosConfiguracoes"
            v-if="!loading"
        >
            <b-modal
                size="xl"
                id="modal-1"
                :title="`Configurações ${modulo.descricao}`"
                ok-only
                ok-title="Accept"
                v-model="mostrar"
                @cancel="esconderModal"
                @close="esconderModal"
                @hide="verificarFechamentoModal"
                :hide-footer="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
            >
                <div v-if="chaveDoModulo === moduloSaudeMental">
                    <b-overlay rounded="lg" opacity="0.5">
                        <div class="tabs">
                            <b-nav>
                                <b-nav-item
                                    :active="tabAtiva"
                                    @click="setTabClick()"
                                >
                                    Questionários
                                </b-nav-item>
                                <b-nav-item
                                    v-if="ativaFeedback"
                                    :active="!tabAtiva"
                                    @click="setTabClick()"
                                >
                                    Feedback personalizado
                                </b-nav-item>
                            </b-nav>
                        </div>
                    </b-overlay>
                    <div v-if="tabAtiva" class="mt-2 pb-5">
                        <validation-observer ref="simpleRules">
                            <validation-provider
                                #default="{ errors }"
                                :rules="MensagemSelecionada != null ? 'required' : ''"
                            >
                                <b-row class="mb-1 align-items-center">
                                    <b-col cols="12" md="auto" class="mb-1 mb-md-0 mr-2">
                                        <span class="title-select-questionario">
                                            Questionário
                                        </span>
                                    </b-col>
                                    <b-col cols="12" md>
                                        <validation-provider #default="{ errors }" :rules="''">
                                            <b-form-group :state="errors.length > 0 ? false : null" class="mb-0">
                                                <v-select
                                                id="Select-questionarios"
                                                v-model="questionariosSelecionados"
                                                multiple
                                                multiselect
                                                variant="custom"
                                                label="descricao"
                                                item-text="descricao"
                                                item-value="id_questionario"
                                                :options="questionarios"
                                                :disabled="disableSelectQuestionarios"
                                                placeholder="Selecione o(s) questionário(s) que será(ão) utilizado(s)"
                                                class="select-questionarios w-100"
                                                >
                                                <span slot="no-options">Nenhum Questionário encontrado.</span>
                                                </v-select>
                                                <small v-if="errors.length > 0" class="text-danger ml-2">Campo Obrigatório</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </validation-provider>
                        </validation-observer>
                        <b-row v-if="questionariosSelecionados.length>0">
                            <b-col md="12" align="center">
                                <b-button
                                type="submit"
                                variant="outline-secondary"
                                class="mr-2 mt-2"
                                @click.prevent="desfazerSelecaoQuestionarios()"
                                >
                                <span>
                                    Limpar
                                </span>
                                </b-button>
                                <b-button
                                type="submit"
                                variant="custom"
                                class="mt-2"
                                @click.prevent="ativarQuestionariosEmpresa()"
                                >
                                <span>
                                    Salvar
                                </span>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row v-if="loadingModal" class="mt-5">
                            <b-col align="center">
                                <b-overlay :show="loadingModal" rounded="lg" opacity="0.6" />
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else>
                        <listFeedback
                            :mensagemSelecionada="mensagemEditar"
                            :grupo="grupo"
                            :modulo="modulo"
                        />
                    </div>
                </div>
                <div v-else>
                    <listFeedback
                        :mensagemSelecionada="mensagemEditar"
                        :grupo="grupo"
                        :modulo="modulo"
                    />
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import listFeedback from "@/views/pages/grupos/ListaFeedbackPersonalizado.vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import "animate.css";
import { modalGenericModel } from '@/libs/sweetalerts';
import { MODULOS, CHAVES_MODULOS  } from "@/custom-enum/modulos-enum.js";
import {
    BCardText,
    BCardHeader,
    BCard,
    BCardBody,
    BForm,
    BButton,
    BRow,
    BModal,
    BCol,
    BFormInput,
    BOverlay,
    BFormTextarea,
    BFormGroup,
    BInputGroupText,
    BCollapse,
    BTable,
    BTableSimple,
    BInputGroup,
    BTbody,
    BTr,
    BTd,
    BNav,
    BNavItem,
} from "bootstrap-vue";

export default {
    props: {
        grupo: {
            type: Object,
            required: true,
        },
        modulo: {
            type: Object,
            required: true,
        },
    },
    components: {
        listFeedback,
        CustomPagination,
        AppCollapse,
        AppCollapseItem,
        BCollapse,
        BCardHeader,
        BCard,
        BInputGroup,
        BInputGroupText,
        BCardBody,
        BTable,
        BTableSimple,
        BTbody,
        BTr,
        BTd,
        BCardText,
        BForm,
        BButton,
        BRow,
        BModal,
        BCol,
        BFormInput,
        BOverlay,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BFormTextarea,
        BFormGroup,
        BNav,
        BNavItem,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loadingLista: false,
            ativaFeedback: false,
            showModalEdit: false,
            moduloSaudeMental: 'SAUDE_MENTAL',
            chaveDoModulo: this.modulo.chave,
            tabAtiva: true,
            MensagemSelecionada: null,
            mostrar: false,
            loading: true,
            loadingModal: false,
            questionarios: [],
            questionariosEmpresa: [],
            semQuestionarios: false,
            questionariosSelecionados: [],
            questionarioSelecionado: null,
            disableSelectQuestionarios: true,
            mensagemEditar: {},
            verificaSaudeMentalAtivoSemQuestionario: false
        };
    },
    mounted() {
        this.buscaQuestionariosPorEmpresa();
    },
    created() {
        this.mostrar = true;
        this.loading = false;
    },
    methods: {
        setTabClick()
        {
            this.tabAtiva = !this.tabAtiva;
        },
        async buscaQuestionariosPorEmpresa(){
            this.loadingModal = true;
            if(this.chaveDoModulo === this.moduloSaudeMental){

                this.disableSelectQuestionarios = true;
                let rota =  this.$helpers.preparaRotaComParametros(
                                this.$api.questionario.listarQuestionariosAtivosPorModulo,
                                "{idModulo}",
                                MODULOS.SAUDE_MENTAL
                            );

                let params = {
                    id_empresa: this.grupo.id_empresa_matriz,
                    chave_modulo: CHAVES_MODULOS.SAUDE_MENTAL
                };
                await this.$http.get(rota).then((res) => {
                    this.questionarios = res.data;
                    this.colocaQuestionariosEmOrdemAlfabetica(this.questionarios);
                });
                await this.$http
                    .get(this.$api.QuestionariosPorEmpresa, { params })
                    .then((res) => {
                        this.questionariosEmpresa = res.data;
                        this.ativaFeedback = res.data.length > 0;

                        this.semQuestionarios = !this.questionariosEmpresa;

                        if(this.questionariosEmpresa.length > 0){
                            if(this.questionariosEmpresa.length <= 1){
                                this.questionarioSelecionado = this.questionariosEmpresa[0];
                            }
                            this.colocaQuestionariosEmOrdemAlfabetica(this.questionarios);
                            this.questionariosSelecionados = this.questionarios.filter(
                                questionario => this.questionariosEmpresa.some(
                                    ativo => ativo.id_questionario === questionario.id_questionario
                                )
                            );
                        }
                    });

                this.disableSelectQuestionarios = false;
            }
            this.loadingModal = false;
            this.verificaSeQuestionarioSaudeMentalEstaAtivo();
        },
        async ativarQuestionariosEmpresa(){
            let _mensagem = {
                title: '',
                subtitle: '',
            };

            if(this.semQuestionarios){
                _mensagem.title    = 'Módulo Ativo';
                _mensagem.subtitle = 'Módulo ativado com sucesso';
            }else{
                _mensagem.title    = 'Módulo Alterado';
                _mensagem.subtitle = 'Módulo alterado com sucesso';
            }

            if(this.questionariosSelecionados.length < 1){
                modalGenericModel(
                    'É necessário escolher ao menos um questionário para ativar o módulo. Selecione pelo menos um questionário antes de sair.',
                    '',
                    require('@/assets/custom-icons/exclamationMark.png'),
                    'ok'
                );
            }else{
                this.loadingModal = true;
                await this.$http.put(this.$api.ModuloAlteraStatus, {
                    id_modulo: MODULOS.SAUDE_MENTAL,
                    id_empresa_grupo: this.grupo.id_empresa_grupo,
                    questionarios: this.questionariosSelecionados
                })
                .then((_response) => {
                    modalGenericModel(
                        _mensagem.title,
                        _mensagem.subtitle,
                        require('@/assets/custom-icons/cora-icons/success.png'),
                        'ok'
                    )
                    .then((result) => {
                        this.questionariosEmpresa = this.questionariosSelecionados;
                        this.esconderModal();
                        this.$emit("retornaModulos");
                    });
                })
                .catch((error) => {
                    modalGenericModel(
                        'Não foi possível realizar essa ação',
                        error.response.data.error,
                        require('@/assets/custom-icons/exclamationMark.png'),
                        'ok'
                    );
                    this.verificaSaudeMentalAtivoSemQuestionario = false;
                    this.esconderModal();
                })
                this.loadingModal = false;
            }
        },
        desfazerSelecaoQuestionarios(){
            this.questionariosSelecionados = [];
        },
        fechaModalEdit() {
            this.showModalEdit = false;
            this.buscaMensagensGrupo(this.dadosPaginacao.paginaAtual);
        },
        IniciarPaginacao(dadosPaginacao) {
            this.dadosPaginacao.daLinha = dadosPaginacao.from;
            this.dadosPaginacao.ateALinha = dadosPaginacao.to;
            this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
            this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
            this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
        },
        buscaMensagensGrupo(page = 1) {
            this.loadingLista = true;
            let rota = this.$helpers.preparaRotaComMultiplosParametros(
                this.$api.QuestoesPersonalizadas,
                {
                    "{empresaGrupo}": this.grupo.id_empresa_grupo,
                    "{modulo}": this.modulo.id_modulo,
                }
            );
            let param = {
                page: page,
                pesquisa: this.textoBusca,
                colunaNome: "id_mensagem",
                colunaOrdem: "asc",
            };
            this.$http.get(rota, { params: param }).then((res) => {
                let aux = res.data.data;
                this.IniciarPaginacao(res.data);
                this.form.SelectMensagens = aux;
                this.tabela.items = [];
                aux.map((item, ind) => {
                    this.$set(this.tabela.items, ind, [item]);
                });
                this.loadingLista = false;

            });
        },
        esconderModal() {
            if(this.questionariosEmpresa.length===0){
                modalGenericModel(
                    'Atenção!',
                    'É necessário escolher ao menos um questionário para ativar o módulo. Selecione pelo menos um questionário antes de sair.',
                    require('@/assets/custom-icons/cora-icons/warning.png'),
                    'ok'
                );
            }else{
                this.$emit("closeModal");
                this.MensagemSelecionada = null;
                this.mostrar = false;
            }
        },
        verificarFechamentoModal(event){
            event.preventDefault();
        },
        colocaQuestionariosEmOrdemAlfabetica(questionarios){
            if(questionarios.length > 1){
                questionarios.sort(function (a, b) {
                    const descA = a.descricao.toLowerCase();
                    const descB = b.descricao.toLowerCase();
                    if (descA < descB) {
                        return -1;
                    }
                    if (descA > descB) {
                        return 1;
                    }
                    return 0;
                })
            }

        },
        verificaSeQuestionarioSaudeMentalEstaAtivo(){
            this.verificaSaudeMentalAtivoSemQuestionario = false;
            this.grupo.modulos.some(modulo => {
                if(modulo.descricao === 'Saúde Mental' && this.questionariosEmpresa.length > 1){
                   if(this.questionariosSelecionados.length === 0){
                    this.verificaSaudeMentalAtivoSemQuestionario = true;
                   }
                }else if(modulo.descricao === 'Saúde Mental' && this.questionariosEmpresa.length <= 1){
                    if(this.questionarioSelecionado === null){
                        this.verificaSaudeMentalAtivoSemQuestionario = true;
                    }
                }
            });
        },
    }
};
</script>

<style scoped>
.cabecalhoLista{
    color:#7030A0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    border-color: #7030A0;
    border-width:1px;
    border-style:solid;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 5px;

}
.tabs {
    background-color: #fff;
    padding: 16px;
    border-radius: 5px;
}
.nav.nav-tabs {
    margin: 0;
    padding: 0;
    justify-content: center;
}
.nav-link {
    background: #fff;
    color: #5e5873;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
}
.nav-link.active {
    background: #7030A0;
    border-color: #7030A0;
    color: #fff;
    border-radius: 0.358rem;

}
.nav-link:after {
    display: none;
}
</style>
