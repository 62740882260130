<template>
  <div class="parametros">
    <b-row>
      <b-col md="4" xl="2" class="mb-1">
        <div class="sidebar-left">
          <div class="sidebar">
            <div class="sidebar-content par-app-sidebar">
              <div class="par-app-menu">
                <h1>Parâmetros</h1>

                <b-list-group>
                  <b-list-group-item
                    v-for="rota in rotas"
                    :key="rota.titulo + $route.path"
                    :to="getRotaParametros(rota.para)"
                    :active="rota.componente == currentTab"
                    @click="setaComponenteAtual(rota.componente)"
                  >
                    <feather-icon :icon="rota.icon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1">{{
                      rota.titulo
                    }}</span>
                    <b-badge
                      v-if="rota.NRelacionados > 0"
                      pill
                      variant="light-warning"
                      class="float-right"
                      >{{ rota.NRelacionados }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="9" xl="9" class="mb-1 conteudo">
        <component
          :is="currentTabComponent"
          @nRel="numeroNaoRelacionados"
          :grupo="grupo"
        ></component>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BListGroup,
  BListGroupItem,
  BBadge,
} from "bootstrap-vue";

import { isDynamicRouteActive } from "@core/utils/utils";
import ParametrosTurno from "@/views/components/custom/parametros/turnos/ParametrosTurnos.vue";
import ParametrosFuncao from "@/views/components/custom/parametros/funcoes/ParametrosFuncoes.vue";
import ParametrosSetor from "@/views/components/custom/parametros/setores/ParametrosSetores.vue";
import ParametrosGerencia from "@/views/components/custom/parametros/gerencias/ParametrosGerencias.vue";
import ParametrosUnidade from "@/views/components/custom/parametros/unidades/ParametrosUnidades.vue";
import ParametrosDiretoria from "@/views/components/custom/parametros/diretorias/ParametrosDiretorias.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    ParametrosTurno,
    ParametrosFuncao,
    ParametrosSetor,
    ParametrosGerencia,
    ParametrosUnidade,
    ParametrosDiretoria,
  },

  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },

  methods: {
    numeroNaoRelacionados(value) {
      let itemComponente = this.rotas.find(
        (rota) => rota.componente === value.parametro
      );
      itemComponente.NRelacionados = value.qtd;
    },
    getRotaParametros() {
      return {
        name: "parametros",
        params: {
          idGrupo: this.grupo.id_empresa_grupo,
        },
      };
    },
    VerificaRelacoes() {
      this.TurnoNaoRelacionados();
      this.FuncoesNaoRelacionados();
    },
    async TurnoNaoRelacionados() {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.EmpresaTurnoSemVinculo,
        "{grupo}",
        this.$route.params.idGrupo
      );
      try {
        this.$http.get(rota).then((res) => {
          this.numeroNaoRelacionados({
            qtd: res.data.length,
            parametro: "ParametrosTurno",
          });
        });
      } catch (error) {
        this.numeroNaoRelacionados({ qtd: 0, parametro: "ParametrosTurno" });
      }
    },
    async FuncoesNaoRelacionados() {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.EmpresaFuncaoSemVinculo,
        "{grupo}",
        this.$route.params.idGrupo
      );
      try {
        this.$http.get(rota).then((res) => {
          this.numeroNaoRelacionados({
            qtd: res.data.length,
            parametro: "ParametrosFuncao",
          });
        });
      } catch (error) {
        this.numeroNaoRelacionados({ qtd: 0, parametro: "ParametrosFuncao" });
      }
    },
    setaComponenteAtual(componente) {
      this.currentTab = componente;
    },
  },
  data() {
    return {
      currentTab: "ParametrosTurno",
      NRelacionados: null,
      rotas: [
        {
          titulo: "Turnos",
          icon: "ClockIcon",
          para: "parametros",
          componente: "ParametrosTurno",
          NRelacionados: null,
        },
        {
          titulo: "Funções",
          icon: "BriefcaseIcon",
          para: "parametros",
          componente: "ParametrosFuncao",
          NRelacionados: null,
        },
        {
          titulo: "Setores",
          icon: "GridIcon",
          para: "parametros",
          componente: "ParametrosSetor",
          NRelacionados: null,
        },

        {
          titulo: "Gerências",
          icon: "CopyIcon",
          para: "parametros",
          componente: "ParametrosGerencia",
          NRelacionados: null,
        },

        {
          titulo: "Diretorias",
          icon: "FeatherIcon",
          para: "parametros",
          componente: "ParametrosDiretoria",
          NRelacionados: null,
        },

        {
          titulo: "Unidade",
          icon: "FlagIcon",
          para: "parametros",
          componente: "ParametrosUnidade",
          NRelacionados: null,
        },
      ],
    };
  },
  mounted() {
    this.VerificaRelacoes();
  },

  computed: {
    currentTabComponent() {
      return this.currentTab;
    },
  },
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      if (filter === "Draft") return "light-warning";
      if (filter === "Spam") return "light-danger";
      return "light-primary";
    };
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    return {
      perfectScrollbarSettings,
      resolveFilterBadgeColor,
      isDynamicRouteActive,
    };
  },
};
</script>
<style lang="scss" scoped >
.conteudo{
      margin-left: 50px;

}
.sidebar {
  h2 {
    margin-left: 15px;
    margin-bottom: 15px;

  }
  .par-app-sidebar {
    width: 210px;
    height: inherit;
    background-color: white;
    transition: all 0.3s ease, background 0s;
    z-index: 210;
    .par-app-menu {
      width: 100%;
      height: 100%;
      z-index: 3;

      .sidebar-menu-list {
        position: relative;
        height: calc(100% - 80px);
      }
    }
  }

  .list-group .list-group-item {
    padding: 0.58rem 1.5rem;
    border: 0;
    font-weight: bold;
    letter-spacing: 0.4px;
    border-left: 2px solid transparent;
    border-radius: 0;
    + .list-group-item.active {
      margin-top: 0;
    }
    &:hover,
    &:focus,
    &.active {
      background: transparent;
      color: #7746ec;
    }
    &.active {
      border-color: #7367f0;
    }
  }
}
</style>

