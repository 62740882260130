<template>
  <div
    class="fake-input"
    :class="className"
    :style="getStyles"
  >
    <span>
      {{ content }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '38px',
    },
    bgColor: {
      type: String,
      default: '#fff',
    },
  },

  computed: {
    getStyles() {
      return {
        height: this.height,
        backgroundColor: this.bgColor,
      }
    },
  },
}
</script>

<style scoped>
.fake-input {
  display: flex;
  align-items: center;
  padding: 0.438rem 1rem;
  width: 100%;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

span {
  word-break: break-word;
}
</style>
