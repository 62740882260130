<template>
  <div id="grupo-pessoas-status-importacao">
    <h2 class="h1">Status de importação</h2>
    <p>
      Acompanhe a importação dos colaboradores. <br />
      Planilha de erro disponível apenas por {{ qtdDiasDisponiveis }} dias.
    </p>

    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6" class="w-100">
      <b-table
        id="listagem-planilha"
        responsive
        sticky-header="380px"
        :fields="cabecalho"
        :items="Planilhas"
        show-empty
      >
        <template #cell(informacoes.status)="data">
          <b-badge
            pill
            class="font-weight-normal"
            :variant="
              data.item.informacoes.status == 'Finalizado'
                ? 'light-primary'
                : 'light-warning'
            "
          >
            {{ data.item.informacoes.status }}
          </b-badge>
        </template>

        <template #empty="">
          <p class="text-center font-size-14">Nenhuma importação realizada.</p>
        </template>

        <template #cell(actions)="data">
          <button
            type="button"
            class="btn botao"
            :class="geraClassCssCustomizacaoBotao(data.item)"
            :disabled="!permitirDownload(data.item)"
            @click="baixarPlanilha(data.item)"
            v-b-tooltip.hover.bottom="data.item.informacoes.disponivel_por"
            :popover-style="{ background: '#2772c0' }"
          >
            <feather-icon icon="DownloadIcon" />
          </button>
        </template>
      </b-table>

      <b-col sm="12">
        <CustomPagination
          :paginacao="dadosPaginacao"
          @page-cliked="atualizarPaginaAtual"
        />
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BOverlay,
  BBadge,
  BButton,
  BCol,
  VBTooltip,
} from "bootstrap-vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
export default {
  components: {
    BTable,
    BOverlay,
    BBadge,
    BButton,
    BCol,
    VBTooltip,
    CustomPagination,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loadingShow: true,
      qtdDiasDisponiveis: 30,
      Planilhas: [],
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
      cabecalho: [
        { key: "nome", label: "Planilha", class: "font-size-14 text-left" },
        {
          key: "criacao",
          label: "Data e Hora",
          class: "font-size-14 text-center",
        },
        {
          key: "informacoes.status",
          label: "status",
          class: "font-size-14 text-center",
        },
        {
          key: "informacoes.resultados.descricao",
          label: "Resultado",
          class: "font-size-14 text-center",
        },
        {
          key: "actions",
          label: "Baixar erros",
          class: "font-size-14 text-center",
        },
      ],
    };
  },
  beforeMount() {
    this.buscaPlanilhas();
  },
  methods: {
    atualizarPaginaAtual(page) {
      this.loadingShow = true;
      this.dadosPaginacao.paginaAtual = page;

      this.dadosPaginacao.daLinha =
        page == 1
          ? 1
          : (page - 1) * this.dadosPaginacao.quantidadeLinhasPadrao + 1;

      let ate =
        page == 1
          ? this.dadosPaginacao.quantidadeLinhasPadrao
          : page * this.dadosPaginacao.quantidadeLinhasPadrao;

      this.dadosPaginacao.ateALinha =
        ate < this.dadosPaginacao.totalLinhas
          ? ate
          : this.dadosPaginacao.totalLinhas;

      this.buscaPlanilhas();
    },
    buscaPlanilhas() {
      let rotaApiFiltro = `${this.$api.IntegracaoPlanilhaBuscarPorIdEmpresaGrupo}?page={idPagina}&porPagina={porPagina}`;

      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        rotaApiFiltro,
        {
          "{idEmpresaGrupo}": this.grupo.id_empresa_grupo,
          "{idPagina}": this.dadosPaginacao.paginaAtual,
          "{porPagina}": this.dadosPaginacao.quantidadeLinhasPadrao,
        }
      );

      this.$http.get(rota).then((res) => {
        this.Planilhas = res.data.data;
        if (res.status != 204) {
          this.dadosPaginacao.totalLinhas = res.data.meta.total;
          this.qtdDiasDisponiveis = res.data.qtd_dias_disponiveis;
        }
        this.loadingShow = false;
      });
    },
    baixarPlanilha(item) {
      this.loadingShow = true;
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.IntegracaoPlanilhaExportar,
        "{idPlanilha}",
        item.id_planilha
      );

      (async () => {
        try {
          await this.$http.get(rota).then((res) => {
            let conteudo = res.data.data.conteudo;
            this.$helpers.baixarArquivoCsv(conteudo, item.nome);
            this.loadingShow = false;
          });
        } catch (err) {
          this.$router.push({ name: "error-404" });
        }
      })();
    },
    geraClassCssCustomizacaoBotao(item) {
      return this.permitirDownload(item)
        ? "btn-outline-primary"
        : "btn-outline-secondary disabled";
    },
    permitirDownload(item) {
      return (
        item.informacoes.status == "Finalizado" &&
        item.informacoes.resultados.erro > 0
      );
    },
  },
};
</script>

<style scoped>
#conteudo h1 {
  display: none;
}
#grupo-pessoas-status-importacao .font-size-14 {
  font-size: 14px;
}

#grupo-pessoas-status-importacao .botao {
  padding: 10px;
}
#grupo-pessoas-status-importacao .btn-outline-primary.baixar {
  color: #2772c0 !important;
  background: #2772c0 !important;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: white;
}
.tooltip-inner {
  background-color: white;
  color: #6e6b7b;
  font-size: 14px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(110, 107, 123, 0.4);
  box-shadow: 0px 0px 7px 0px rgba(110, 107, 123, 0.4);
}
</style>
