<template>
    <div class="d-flex flex-row justify-content-between">
        <label v-if="paginacao.totalLinhas >0"  for="pagination" class="label-amount-table mb-0 d-none d-md-block ">
            Mostrando {{ paginacao.daLinha }} a {{ paginacao.ateALinha }} de {{ paginacao.totalLinhas }} resultados
        </label>
        <label v-else  class="label-amount-table mb-0 " for="pagination">
            Mostrando 0 resultados
        </label>

        <b-pagination
            id="pagination"
            v-model="paginacao.paginaAtual"
            :total-rows="paginacao.totalLinhas"
            :per-page="paginacao.quantidadeLinhasPadrao"
            align="right"
            size="lg"
            pills
            @page-click="handlePage"
            hide-goto-end-buttons
            hide-ellipsis
        >
            <template #prev-text>
                <feather-icon class="text-custom" icon="ChevronLeftIcon" size="20"/>
            </template>
            <template #next-text>
                <feather-icon class="text-custom" icon="ChevronRightIcon" size="20"/>
            </template>
        </b-pagination>
    </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';

export default {
    props: {
        paginacao: {
            required: true,
        }
    },

    components: {
        BPagination
    },

    methods: {
        handlePage(event, page) {
            this.$emit("page-cliked", page);
        }
    }
}
</script>

<style>
    .pagination.b-pagination.pagination-lg.justify-content-end button[aria-checked="true"] {
        border: 2px solid #7030A0;
        background-color: #F8F8F8;
        color: #5E5873 !important;
        font-weight: bold;
        font-size: 16px;
    }
</style>
