import { render, staticRenderFns } from "./ListaSetores.vue?vue&type=template&id=23ea6b07&scoped=true&"
import script from "./ListaSetores.vue?vue&type=script&lang=js&"
export * from "./ListaSetores.vue?vue&type=script&lang=js&"
import style0 from "./ListaSetores.vue?vue&type=style&index=0&id=23ea6b07&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ea6b07",
  null
  
)

export default component.exports