<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Escolaridade"
      >
        <b-overlay
          :show="loading"
          rounded="lg"
          :opacity="0.8"
          class="custom-overlay-color"
        >
          <v-select
            id="Select-Escolaridade"
            v-model="EscolaridadeSelecionado"
            variant="custom"
            item-text="descricao"
            item-value="id_escolaridade"
            label="descricao"
            :options="Escolaridade"
            placeholder="Escolaridade"
            :disabled="Disabled"
            @input="SelecionaEscolaridade"
          >
            <span slot="no-options">Nenhuma Escolaridade encontrada.</span>
          </v-select>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >Campo Obrigatório</small>
        </b-overlay>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import { BCol, BFormGroup, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
    BOverlay,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    idEscolaridade: {
      type: Number,
      default: 0,
      required: false,
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,

      EscolaridadeSelecionado: '',
      Escolaridade: [],
      NomeLabel: 'Escolaridade',
      Disabled: false,
    }
  },
  watch: {
    values(value) {
      if (value?.id_escolaridade) {
        this.EscolaridadeSelecionado = value
        return;
      }
        this.EscolaridadeSelecionado = null
    },
    idEscolaridade(value) {
      this.EscolaridadeSelecionado = this.Escolaridade.filter(
        x => x.id_escolaridade === value,
      )
      this.$emit('getEscolaridade', this.EscolaridadeSelecionado[0])
    },
    disabledProp(value) {
      this.Disabled = value
    },
  },

  created() {
    if (this.required) {
      this.NomeLabel += ' *'
    }
    this.buscaEscolaridadeResponsavel()
  },
  methods: {
    SelecionaEscolaridade() {
      this.$emit('getEscolaridade', this.EscolaridadeSelecionado)
    },
    async buscaEscolaridadeResponsavel() {
      this.loading = true

      await this.$http.get(this.$api.EscolaridadeBusca).then(res => {
        this.Escolaridade = res.data
        this.EscolaridadeSelecionado = this.Escolaridade.filter(
          x => x.id_escolaridade === this.idEscolaridade,
        )
        this.$emit('getEscolaridade', this.EscolaridadeSelecionado[0])
      })

      this.loading = false
    },
  },
}
</script>
