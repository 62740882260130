<template>
  <b-overlay
    :show="loadingShow"
    rounded="lg"
    opacity="0.6"
  >
    <div
      v-if="Object.keys(Grupo).length"
      id="grupo-detalhe"
      class="bg-white"
    >
      <!-- header -->
      <div id="header">
        <div
          class="background"
          :style="`background: url(${background})`"
        >
          <div id="component-profile">
            <div class="profile-header">
              <div class="profile-img-container d-flex align-items-center">
                <div class="profile-img">
                  <span
                    class="b-avatar align-baseline badge-custom rounded-0"
                  ><span
                    class="b-avatar-text"
                  ><span v-text="Grupo.descricao.charAt(0)" /></span></span>
                </div>
                <div class="profile-title ml-3">
                  <h1
                    class="text-white"
                    v-text="Grupo.descricao"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- tabs -->
        <div class="tabs">
          <b-nav
            tabs
            align="center"
          >
            <div
              v-for="rota in rotas"
              v-show="rota.visible"
              :key="rota.para"
            >
              <!-- item com submenu -->
              <b-nav-item-dropdown
                v-if="rota.para == 'pessoas'"
                no-caret
                :class="$route.name == 'pessoas' ? 'ativa' : 'normal'"
                :toggle-class="
                  $route.name == 'pessoas' ? 'text-white' : 'variant'"
                text="Pessoas"
              >
                <b-dropdown-item

                  v-for="subMenu in rota.subMenu"
                  v-show="subMenu.visible"
                  :key="subMenu.componente"
                  :to="getRotaParametros(rota.para)"
                  @click="
                    setaComponenteAtual(rota.componente, subMenu.componente)
                  "
                >
                  {{ subMenu.titulo }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <!-- item com submenu -->

              <b-nav-item
                v-else
                :to="getRotaParametros(rota.para)"
                :active="rota.para == $route.name"
                @click="setaComponenteAtual(rota.componente)"
              >
                {{ rota.titulo }}
              </b-nav-item>
            </div>
          </b-nav>
        </div>
        <!-- tabs -->
      </div>
      <!-- header -->

      <section id="conteudo">
        <div class="tab-content">
          <!-- notificacoes -->
          <b-col md="12">
            <b-alert
              v-if="mostraAlertResp"
              id="sem-responsavel"
              variant="warning"
              show
              class="custom mb-0"
            >
              <div class="alert-body">
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
                />
                <strong class="">Matriz</strong> Selecione a Pessoa Responsável
                pela empresa
              </div>
            </b-alert>
            <div
              v-for="(item, id) in Grupo.filiais"
              :key="id"
            >
              <b-alert
                v-if="item.responsaveis == null"
                id="sem-responsavel"
                variant="warning"
                show
                class="custom mb-0"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-50"
                  />
                  <strong class="">Filial - {{ item.razao }}</strong> Selecione
                  a Pessoa Responsável pela empresa
                </div>
              </b-alert>
            </div>
          </b-col>
          <!-- notificacoes -->

          <b-card>
            <component
              :is="currentTabComponent"
              v-if="!loadingShow"
              :grupo="Grupo"
              :pessoa-aba-selecionada="PessoaAbaSelecionada"
              @trocaComponente="trocaComponente"
            />
          </b-card>
        </div>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BCard,
  BCol,
  BDropdownItem,
  BNav,
  BNavItem,
  BNavItemDropdown,
  BOverlay,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import ProfileHeader from '@/views/pages/profile/ProfileHeader.vue'
import { modalGenericModel } from '@/libs/sweetalerts'
import GrupoMatriz from './GrupoMatriz.vue'
import GrupoFiliais from './GrupoFiliais.vue'
import GrupoPessoas from './GrupoPessoas.vue'
import GrupoParametros from './GrupoParametros.vue'
import GrupoModulos from './GrupoModulos.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BNav,
    BNavItem,
    BSpinner,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BNavItemDropdown,
    BDropdownItem,
    ProfileHeader,
    GrupoMatriz,
    GrupoFiliais,
    GrupoPessoas,
    GrupoParametros,
    GrupoModulos,
  },
  data() {
    return {
      Grupo: {},
      alertResponsaveis: true,
      currentTab: null,
      PessoaAbaSelecionada: 'GrupoPessoasListar',
      url: null,
      loadingShow: true,
      background: require('@/assets/images/backgrounds/grupo-detalhe.svg'),
      FormEditarCadastrar: {
        Titulo: '',
        Grupo: {},
        Empresa: {},
        Modulos: {},
        Regionais: {},
        EmpresaTipo: 1,
      },
      rotas: [
        {
          titulo: 'Matriz',
          para: 'matriz',
          componente: 'GrupoMatriz',
          visible: true,
        },
        {
          titulo: 'Filiais',
          para: 'filiais',
          componente: 'GrupoFiliais',
          visible: true,
        },
        {
          titulo: 'Pessoas',
          para: 'pessoas',
          componente: 'GrupoPessoas',
          visible: true,

          subMenu: [
            {
              componente: 'GrupoPessoasListar',
              titulo: 'Buscar Pessoas',
              visible: true,
            },
            {
              componente: 'GrupoPessoasImportar',
              titulo: 'Importar',
              visible: true,
            },
            {
              componente: 'CriarPessoaForm',
              titulo: 'Cadastrar',
              visible: this.$can('INSERIR','PORTAL_GRUPO_PESSOAS'),
            },
            {
              componente: 'GrupoPessoasStatusImportacao',
              titulo: 'Status Importação',
              visible: true,
            },
            {
              componente: 'EditarPessoaForm',
              titulo: 'Editar',
              visible: false
            }
          ]
        },
        {
          titulo: 'Parâmetros',
          para: 'parametros',
          componente: 'GrupoParametros',
          visible: true,
        },
        {
          titulo: 'Módulos',
          para: 'modulos',
          componente: 'GrupoModulos',
          visible: true,
        },
      ],
    }
  },
  computed: {
    currentTabComponent() {
      return this.currentTab
    },
    mostraAlertResp() {
      let ret
      if (this.$route.name !== 'matriz') {
        return false
      }
      try {
        ret = this.Grupo.matriz.responsaveis == null
      } catch (e) {
        ret = true
      }
      return ret
    },
  },
  watch: {
    url() {
      this.atualizaOsDadosTela()
    },
  },
  mounted() {
    this.setaComponenteAtual()
  },
  methods: {
    setaComponenteAtual(
      componente = null,
      subComponentePessoa = 'GrupoPessoasListar',
    ) {
      const { params } = this.$route
      const itemComponente = this.rotas.find(
        rota => rota.para === this.$route.name,
      )
      this.PessoaAbaSelecionada = subComponentePessoa

      const url = this.$helpers.preparaRotaComParametros(
        this.$api.GrupoDetalhe,
        '{grupo}',
        params.idGrupo,
      )

      this.url = this.$helpers.preparaRotaComParametros(
        url,
        '{relacionamento}',
        itemComponente.para,
      )

      this.currentTab = componente || itemComponente.componente
    },
    async atualizaOsDadosTela() {
      this.loadingShow = true

      await this.$http.get(this.url)
        .then(res => {
          this.Grupo = res.data.data
        })
        .catch(() => {
          modalGenericModel(
            'Não foi possível realizar a sua solicitação',
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          )
        })

      this.loadingShow = false
    },
    getRotaParametros(tab) {
      return {
        name: tab,
        params: {
          idGrupo: this.Grupo.id_empresa_grupo,
        },
      }
    },
    trocaComponente(objetoComponente){
      this.currentTab = objetoComponente.componente
      this.PessoaAbaSelecionada = objetoComponente.subComponente
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";

.ativa {
  background: $custom-purple;
  border-color: $custom-purple;
  color: $white;
  border-radius: 0.358rem;
}

.normal {
  margin: 0;
  padding: 0;
  background: $white;
  color: #5e5873;
}
#grupo-detalhe #header {
  margin-bottom: 20px;

  .background {
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    height: 136px;
    background-repeat: no-repeat;
    background-color: #7b00cd;

    .avatar {
      height: 117px;
      width: 117px;
      background-color: #002060;

      span {
        font-size: 57px;
      }
    }
  }
  .tabs {
    background-color: $white;
    padding: 11px 0;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;

    .nav.nav-tabs {
      margin: 0;
      padding: 0;

      .nav-link {
        background: $white;
        color: #5e5873;
      }
      .nav-link.active {
        background: $custom-purple;
        border-color: $custom-purple;
        color: $white;
        border-radius: 0.358rem;
      }
      .nav-link:after {
        display: none;
      }
    }
  }
}

#component-profile .profile-header .profile-img-container {
  bottom: 0;
  position: unset;
  margin: 40px 0 0 40px;
}

@media only screen and (max-width: 823px) {
  #component-profile .profile-header .profile-img-container {
    margin-top: 20px;

    .profile-img {
      height: 6.92rem;
      width: 6.92rem;
    }
  }
}
</style>
