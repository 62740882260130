export const PERFIL = {
  USUARIO_ADMIN_MASTER: 1,
  USUARIO_ADMIN_MODULO: 2,
  OPERADOR: 3,
  RH: 4,
  SAUDE: 5,
  LIDER: 6,
  TRABALHADOR: 7
}

export const HIERARQUIA = {
  USUARIO_ADMIN_MASTER: 1,
  USUARIO_ADMIN_MODULO: 2,
  OPERADOR: 3,
  RH: 4,
  SAUDE: 5,
  LIDER: 6,
  TRABALHADOR: 7
}
