<template>
  <Form
    ref="form"
    :form-data="formData"
    :disable="false"
    :company-type="getCompanyType"
    :use-title="useTitle"
    :title="title"
    :popover-info="popoverInfo"
    mode="update"
    overlay-opacity="0.8"
    @clearForm="limparForm"
  />
</template>

<script>
import Form from '@/views/pages/empresas/Form.vue'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    Form,
  },

  props: {
    empresa: {
      type: Object,
      required: true,
    },
    useTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    popoverInfo: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: true,

      formData: {
        idEmpresa: '',
        cnpj: '',
        razao: '',
        nomeFantasia: '',
        cep: '',
        cidade: null,
        estado: null,
        responsavel: null,
        modulos: [],
        regionais: [],
      },
    }
  },

  computed: {
    getEmpresa() {
      return this.empresa
    },

    getCompanyType() {
      return this.$store.state.modal.tipoEmpresa
    },
  },

  mounted() {
    this.listarEmpresaId()
  },

  methods: {
    async listarEmpresaId() {
      this.$refs.form.loading = true

      const route = this.$api.EmpresasId(this.getEmpresa.id_empresa)

      await this.$http.get(route)
        .then(response => {
          const {
            id_empresa,
            razao,
            nome_fantasia,
            cnpj,
            cep,
            cidade,
            regionais,
            relacionamento_grupo_matriz,
            colaboradores,
          } = response.data

          this.formData = {
            idEmpresa: id_empresa,
            nomeFantasia: nome_fantasia,
            razao,
            cnpj,
            cep,
            cidade,
            estado: { uf: cidade.uf, descricao: cidade.estado.nome },
            regionais,
            modulos: relacionamento_grupo_matriz ? relacionamento_grupo_matriz.modulos : null,
            responsavel: colaboradores ? colaboradores[0] : null,
          }
        })
        .catch(() => {
          modalGenericModel(
            'Não foi possível realizar a sua solicitação.',
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          )
        })

      this.$refs.form.loading = false
    },

    limparForm() {
      this.formData = {
        idEmpresa: '',
        cnpj: '',
        razao: '',
        nomeFantasia: '',
        cep: '',
        cidade: null,
        responsavel: null,
        modulos: [],
        regionais: [],
      }

      this.$emit('clearForm')
    },
  },
}
</script>

