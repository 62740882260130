<template>
  <div id="detalhe-grupo-filiais">
    <b-overlay
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    >
      <div
        v-if="Object.keys(grupo).length"
        id="grupo-detalhe"
        class="bg-white"
      >
        <!-- cabecalho -->
        <b-row class="mb-3">
          <b-col
            md="9"
            sm="12"
          >
            <h1>Filiais</h1>
          </b-col>
          <b-col
            md="3"
            sm="12"
          >
            <b-button
              type="button"
              variant="custom"
              class="botao float-lg-right"
              :disabled="!matriz.ativo"
              @click="showModalCadastroFilial"
            >
              <feather-icon icon="PlusCircleIcon" />
              Incluir Nova Filial
            </b-button>
          </b-col>
        </b-row>
        <!-- cabecalho -->
        <!-- conteudo -->
        <b-row>
          <EmpresaTable
            :itens-tabela="grupo.filiais"
            :pagina-buscar-empresa="false"
            :grupo-modulos="grupo.modulos"
            :grupo="grupo"
            @mostrarModalAtualizarFilial="showModalAtualizarFilial"
          />
        </b-row>
        <!-- conteudo -->
      </div>
    </b-overlay>

    <!-- Modal inserir filial -->
    <modal-form
      :modal-state="modalInserirFilial"
      :modal-title="getTituloModal"
      @closeModal="esconderModal"
    >
      <inserir-empresa
        :use-title="false"
        :id-empresa-grupo="grupo.id_empresa_grupo"
        @clearForm="esconderModal"
      />
    </modal-form>

    <!-- Modal editar filial -->
    <modal-form
      :modal-state="modalAtualizarFilial"
      :modal-title="getTituloModal"
      @closeModal="esconderModal"
    >
      <atualizar-empresa
        :use-title="false"
        :id-empresa-grupo="grupo.id_empresa_grupo"
        :empresa="empresaFilial"
        @clearForm="esconderModal"
      />
    </modal-form>
  </div>
</template>

<script>
import {
  BButton, BCol, BOverlay, BRow,
} from 'bootstrap-vue'
import EmpresaTable from '@/views/components/custom/empresas/EmpresaTable.vue'
import ModalForm from '@/views/pages/empresas/components/ModalForm.vue'
import InserirEmpresa from '@/views/pages/empresas/InserirEmpresa.vue'
import AtualizarEmpresa from '@/views/pages/empresas/AtualizarEmpresa.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    EmpresaTable,
    ModalForm,
    InserirEmpresa,
    AtualizarEmpresa,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingShow: false,
      matriz: {},

      empresaFilial: null,

      modalInserirFilial: false,
      modalAtualizarFilial: false,
    }
  },

  computed: {
    getEmpresa() {
      return this.$store.state.modal.empresa
    },

    getTituloModal() {
      return this.$store.state.modal.tituloModal
    },

    getEditarFilialPelaBuscaGeral() {
      return this.$store.state.modal.editarFilialPelaBuscaGeral
    },
  },

  watch: {
    '$store.state.modal.modalInserirFilial': {
      immediate: true,
      handler() {
        this.modalInserirFilial = this.$store.state.modal.modalInserirFilial
      },
    },

    '$store.state.modal.modalAtualizarFilial': {
      immediate: true,
      handler() {
        this.modalAtualizarFilial = this.$store.state.modal.modalAtualizarFilial
      },
    },
  },

  created() {
    this.dadosMatriz()

    if (this.getEditarFilialPelaBuscaGeral) {
      this.empresaFilial = this.$store.state.modal.empresa
    }
  },

  methods: {
    dadosMatriz() {
      this.$http.get(this.$api.EmpresasId(this.grupo.id_empresa_matriz)).then(res => {
        this.matriz = res.data
      })
    },

    showModalCadastroFilial() {
      this.$store.commit('modal/exibirModalInserirFilial', {
        tituloModal: 'Incluir nova Filial',
        tipoEmpresa: 2,
      })
    },

    showModalAtualizarFilial(empresa) {
      this.empresaFilial = empresa

      this.$store.commit('modal/exibirModalAtualizarFilial', {
        empresa: this.empresaFilial,
        tituloModal: `Editar - Filial: ${this.empresaFilial.razao}`,
        tipoEmpresa: 2,
        mostrarModal: true,
      })
    },

    esconderModal() {
      this.$store.commit('modal/limparState')

      this.$emit('atualizarDadosTela')
    },

    getDescritivoRegionais() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.grupo.matriz.regionais,
        'descricao',
        'Não informado',
      )
    },
    getDescritivoModulos() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.grupo.modulos,
        'descricao',
        'Não possui',
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/grupo.scss";
</style>
