<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      rules="required"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="NomeLabel"
        label-for="Select-Responsavel"
      >
        <vue-autosuggest
          ref="autocomplete"
          v-model="ResponsavelSelecionado"
          :suggestions="suggestions"
          :input-props="inputProps"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          variant="primary"
          @selected="selecionar"
          @input="fetchResults"
          @change="verificaAlteracao"
        />

        <small
          v-if="errors.length > 0"
          class="text-danger"
        >Selecione um Responsável</small>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'App',
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  props: {
    empresa: {
      type: Object,
      default: null,
    },
    values: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      NomeLabel: 'Pessoa Responsável *',

      ResponsavelSelecionado: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 300,
      usersUrl: 'https://jsonplaceholder.typicode.com/users',
      inputProps: {
        id: 'autosuggest__input',
        onInputChange: this.fetchResults,
        placeholder: 'Responsável',
        class: 'form-control',
        name: 'responsavel',
      },
      suggestions: [],
      retornoConsulta: [],
    }
  },

  watch: {
    values(value) {
      if (value) {
        this.ResponsavelSelecionado = value.nome
        this.$emit('setResponsavel', value)
      }
    },
  },

  methods: {
    verificaAlteracao() {
      this.ResponsavelSelecionado = ''
    },
    selecionar(item) {
      this.ResponsavelSelecionado = item.item.nome
      this.$emit('setResponsavel', item.item)
    },

    fetchResults(val) {
      clearTimeout(this.timeout)
      if (this.retornoConsulta.length === 0) {
        this.timeout = setTimeout(() => {
          const rotaResponsaveis = this.$api.EmpresaResponsavel(this.empresa.idEmpresa)

          this.$http.get(rotaResponsaveis).then(res => {
            this.suggestions = []
            this.selected = null
            this.retornoConsulta = res.data
            const colaboradores = this.filterResults(
              this.retornoConsulta,
              val,
              'nome',
            )
            colaboradores.length
              && this.suggestions.push({ data: colaboradores })
          })
        }, this.debounceMilliseconds)
      } else {
        this.suggestions = []
        this.selected = null

        const colaboradores = this.filterResults(this.retornoConsulta, val, 'nome')
        colaboradores.length && this.suggestions.push({ data: colaboradores })
      }
    },
    filterResults(data, text, field) {
      return data
        .filter(item => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field]
          }
        })
        .sort()
    },
    renderSuggestion(suggestion) {
      return suggestion.item.nome
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.nome
    },
  },
}
</script>

<style>
#app {
  color: #2c3e50;
}

#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  border: 1px solid #616161;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {

  position: absolute;
  z-index: 100;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 200px;
  overflow-y: scroll;
  background: white;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
}
.autosuggest__results li {
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 15px;
}

.autosuggest__results {
  cursor: pointer;
}
.autosuggest__results-item:hover {
  background-color: #eeedff;
  color: #7030A0;
}
</style>
