<template>
  <div>
    <h2>Diretorias</h2>
    <h4>Adicionar novo Diretoria</h4>
    <b-alert variant="danger" show>
      <div v-if="erros != null" class="alert-body">
        <strong>Registro não efetuado </strong>
        <ul id="array-with-index">
          <li v-for="erro in erros" :key="erro.errors">
            {{ erro }}
          </li>
        </ul>
      </div>
    </b-alert>

    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <validation-observer ref="simpleRules">
        <b-form id="DiretoriaForm" ref="DiretoriaForm" @submit.prevent="Salvar">
          <b-row>
            <b-col xl="5" md="5">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label="Diretoria de acordo com a Empresa"
                  label-for="descricao"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.descricao"
                    id="descricao"
                    name="descricao"
                    :disabled="false"
                    v-on:keyup.enter="$event.target.blur()"

                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >Campo Obrigatório</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col xl="5" md="5">
              <br />
              <b-button
                :disabled="disableSalvar"
                type="submit"
                variant="custom"
                @click.prevent="Salvar"
              >
                Incluir
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>

    <listaDiretorias :carregar="carregar" @geraErro="geraErro" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import listaDiretorias from "@/views/components/custom/parametros/diretorias/ListaDiretorias.vue";
import { modalGenericModel } from '@/libs/sweetalerts'

import {
  BCard,
  BCol,
  BRow,
  BButton,
  BAlert,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCard,
    BRow,
    BButton,
    BAlert,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    BCol,
    listaDiretorias,
  },
  watch: {
    loadingShow(value) {
      this.disableSalvar = value;
    },
  },

  data() {
    return {
      disableSalvar: false,
      carregar: false,
      loadingShow: false,
      erros: null,
      form: {
        descricao: ""
      }
    };
  },
  methods: {
    FormataDadosFormulario() {
      return {
        id_empresa_grupo: this.$route.params.idGrupo,
        descricao: this.form.descricao,
      };
    },
    geraErro(value) {
      this.erros = value;
    },
    limpaForm() {
      this.form.descricao = "";
      this.$refs.simpleRules.reset();
    },
    async SalvarDados() {
      let form = this.FormataDadosFormulario("create");
      this.loadingShow = true;
      this.carregar = false;
      this.$refs.simpleRules.reset();
      try {
        await this.$http
          .post(this.$api.CadastroEmpresaDiretoria, form)
          .then((res) => {
            if (res.status == 201) {
              modalGenericModel(
                'Sucesso!',
                'Salvo com Sucesso',
                require('@/assets/custom-icons/cora-icons/success.png'),
                'Ok'
              ).then(() => {
                this.carregar = true;
                this.limpaForm();
                this.erros = null;
                this.loadingShow = false;
              })
            }
          });
      } catch (error) {
        this.loadingShow = false;

        this.erros = error.response.data.error
          ? error.response.data.error
          : error.response.data.errors;
      }
    },
    Salvar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.SalvarDados();
        }
      });
    },
  },
};
</script>

<style scoped>
.alert-body {
  margin-top: 20px;
  margin-bottom: 20px;
}
.botInc {
  margin-left: auto;
  margin-right: 15px;
}
h4 {
  margin-top: 15px;
}
</style>
