<template>
<div
  class="scrollable"
  ref="meuModal"
  id="GrupoModulosConfiguracoes"
>
    <b-modal
        v-model="showModalEdit"
        id="modal-empresa"
        ref="modal-empresa"
        size="xl"
        centered
        hide-footer
        @close="fechaModalEdit"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
    >
        <formEdit
            v-if="true"
            :mensagemSelecionada="mensagemEditar"
            :grupo="grupo"
            @closeModal="fechaModalEdit"
        />
    </b-modal>

    <b-card>
        <b-overlay :show="loading" rounded="lg" opacity="0.6">
            <div v-if="modulo.chave === 'SAUDE_MENTAL'">
                <div
                    v-if="questionariosSelect.length > 1"
                    class="organizador-questionario-nome-empresa"
                >
                    <div class="pr-1 mb-1">
                        <div class="d-block d-sm-flex align-items-sm-center">
                            <div class="titulo mr-1">
                                Selecionar um questionário
                            </div>
                            <v-select
                                v-model="questionarioSelecionado"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="questionariosSelect"
                                :clearable="false"
                                class="custom-select-business mt-1 mb-1 mt-sm-0 mb-sm-0" 
                                label="descricao"
                                item-text="descricao"
                                item-value="chave"
                                placeholder="Selecionar"
                            >
                                <span slot="no-options">Nenhum questionário encontrado.</span>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>

            <b-row class="mb-2" v-if="questionarioSelecionado">
                <b-col md="12" xl="12" class="busca">
                    <b-form-group
                        label="Pesquisar Mensagem/Pergunta"
                        label-for="busca"
                        class="m-0 font-medium-2"
                    >
                        <b-input-group v-on:keyup.enter="buscaMensagensGrupo(1)">
                            <template #append>
                                <b-input-group-text>
                                    <feather-icon
                                        v-on:click="buscaMensagensGrupo(1)"
                                        icon="SearchIcon"
                                    />
                                </b-input-group-text>
                            </template>
                            <b-form-input
                                v-model="textoBusca"
                                id="busca"
                                name="busca"
                                placeholder="Pesquisar Mensagem/Pergunta"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-if="modulo.chave === 'SAUDE_MENTAL'">
                <div v-if="questionarioSelecionado">
                    <validation-observer ref="simpleRules">
                        <validation-provider
                            #default="{ errors }"
                            :rules="MensagemSelecionada != null ? 'required' : ''"
                        >
                            
                            <div v-if="MensagemSelecionada != null">
                                <b-form-group
                                    :state="errors.length > 0 ? false : null"
                                    label="Feedback Personalizado"
                                    label-for="FeedbackPersonalizado"
                                    class="mt-1"
                                >
                                    <b-form-textarea
                                        id="FeedbackPersonalizado"
                                        placeholder=""
                                        rows="8"
                                        v-model="MensagemSelecionada.mensagem"
                                    />
                                </b-form-group>
                            </div>
                        </validation-provider>
                    </validation-observer>
                    <b-overlay :show="loadingLista" rounded="lg" opacity="1">
                        <div class="accordion" role="tablist">
                            <b-card
                                no-body
                                class="mb-0"
                                v-for="(item, index) in tabela.items"
                                :key="index"
                            >
                                <b-card-header
                                    header-tag="header"
                                    class="spacing-between-answers"
                                    role="tab"
                                    variant="custom"
                                >
                                    <div
                                        class="cabecalhoLista text-uppercase"
                                        @click="expandirPerguntas(index)"
                                        variant="custom"
                                    >
                                        <feather-icon
                                            icon="ChevronDownIcon"
                                            size="18"
                                            class="mr-75"
                                        />
                                        MENSAGEM PERSONALIZÁVEL {{ item.dinamicIndex }}
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    :visible="collapsePerguntas[index]"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <b-table
                                            id="listPerguntas"
                                            responsive
                                            sticky-header="100%"
                                            :busy.sync="tabela.tabelaOcupada"
                                            :no-local-sorting="true"
                                            :fields="tabela.fields"
                                            :items="[item]"
                                        >
                                            <template #cell(personalizada)="row">
                                                <div v-if="row.item.statuspersonalizada==true">
                                                    {{ row.item.personalizada }}
                                                </div>
                                                <div v-else class="text-danger text-italic">
                                                    Esta mensagem não foi personalizada
                                                </div>
                                            </template>
                                            <template #cell(acoes)>
                                                <b-button @click="editarMensagem(item)" variant="outline-primary" class="btn-icon">
                                                    <feather-icon icon="EditIcon" />
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </b-overlay>
                </div>
                <b-col sm="12" class="py-2" v-if="questionarioSelecionado != null">
                    <CustomPagination
                        :paginacao="dadosPaginacao"
                        @page-cliked="atualizarPaginaAtual"
                    />
                    <b-button v-if="botaoExcluirFeedback"
                        @click="excluirPersonalizado"
                        variant="custom"
                        class="btn btn-insert float-left"
                    >
                        Excluir feedback Personalizado
                    </b-button>
                </b-col>
            </div>
        </b-overlay>
    </b-card>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import formEdit from "@/views/pages/grupos/FeedBackPersonalizadoForm.vue";
import { modalGenericModel } from '@/libs/sweetalerts';
import {
    BModal,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupText,
    BFormInput,
    BFormTextarea,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BTable
} from "bootstrap-vue";
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CustomPagination,
        BModal,
        BOverlay,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupText,
        BFormInput,
        BFormTextarea,
        BButton,
        BCard,
        BCardHeader,
        BCollapse,
        BCardBody,
        BTable,
        vSelect,
        formEdit
    },
    directives: {
        Ripple,
    },
    props: {
        grupo: {
            type: Object,
            required: true,
        },
        modulo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            textoBusca: "",
            readMoreActivated: [],
            allOpenRows: [],
            showModal: false,
            questionarioSelecionado: null,
            moduloSaudeMental: 'SAUDE_MENTAL',
            chaveDoModulo: this.modulo.chave,
            questionariosSelect: [],
            MensagemSelecionada: null,
            mensagemEditar: {},
            showModalEdit: false,
            botaoExcluirFeedback: false,
            loading: false,
            loadingLista: false,
            termoBusca: "",
            collapsePerguntas: [],
            dadosPaginacao: {
                paginaAtual: 1,
                totalLinhas: 1,
                daLinha: 1,
                ateALinha: 1,
                quantidadeLinhasPadrao: 5,
            },
            form: {
                SelectMensagens: [],
            },
            tabela: {
                semDados: false,
                erroTabela: false,
                tabelaOcupada: false,
                quantidadeLinhasVisualizar: [10, 25, 50, 100],
                ordem: "",
                campoOrdenado: "",
                fields: [
                    { key: "acoes", label: "ações", class: "width-custom-column" },
                    {
                        key: "mensagem_padrao",
                        label: "Padrão",
                    },
                    {
                        key: "personalizada",
                        label: "Personalizada",
                    },
                ],
                items: [],
            }
        };
    },
    mounted() {
        this.loading = true;
        this.preencheSelectQuestionarios();
    },
    computed: {
        desabilitarSalvar() {
            return (
                this.MensagemSelecionada == null ||
                this.MensagemSelecionada.mensagem == null ||
                this.MensagemSelecionada.mensagem == ""
            );
        },
    },
     watch: {
      "questionarioSelecionado": {
        handler(value) {
          this.buscaMensagensGrupo()
        },
        deep: true,
      }
    },
    methods: {
        async preencheSelectQuestionarios(){
            if(this.chaveDoModulo === this.moduloSaudeMental){
                let params = {
                    id_empresa: this.grupo.id_empresa_matriz,
                    chave_modulo: this.modulo.chave
                };
                await this.$http
                    .get(this.$api.QuestionariosPorEmpresa, { params })
                    .then((res) => {
                        if (res.status == 204) {
                            this.modalModuloSemQuestionarios()
                        }else{
                            this.questionariosSelect = res.data
                            this.questionarioSelecionado = this.questionariosSelect.length == 1 ? this.questionariosSelect[0] : null 
                        }
                    })
                    .catch(() => {
                        this.$router.push({ name: "error-404" });
                    });
            }
            this.loading = false
        },
        editarMensagem(value) {
            this.mensagemEditar = value;
            this.showModalEdit = true;
        },
        expandirPerguntas(index) {
            this.collapsePerguntas.map((item, i) => {
                this.collapsePerguntas[i] = index == i ? item : false;
            });

            this.$set(this.collapsePerguntas, index, !this.collapsePerguntas[index]);
        },
        atualizarPaginaAtual(page) {
            this.dadosPaginacao.paginaAtual = page;
            this.buscaMensagensGrupo(page);
        },
        IniciarPaginacao(dadosPaginacao) {
            this.dadosPaginacao.daLinha = dadosPaginacao.from;
            this.dadosPaginacao.ateALinha = dadosPaginacao.to;
            this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
            this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
            this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
        },
        buscaMensagensGrupo(page = 1) {
            this.loadingLista = true;
            let params = {
                "id_empresa_grupo": this.grupo.id_empresa_grupo,
                "id_modulo": this.modulo.id_modulo,
                "chave_questionario": this.questionarioSelecionado.chave,
                page: page,
                perPage: 10,
                columnOrder: "asc",
                columnName: "id_mensagem",
                pesquisa: this.textoBusca,
            };
            this.$http.get(this.$api.QuestoesPersonalizadas, { params: params }).then((res) => {
                let aux = res.data.data;
                let dinamicIndex = res.data.from;
                aux.map((item) => {
                    item.dinamicIndex = dinamicIndex;
                    dinamicIndex++
                });
                this.IniciarPaginacao(res.data);
                this.form.SelectMensagens = aux;
                this.tabela.items = aux;
                this.loadingLista = false;
                if(this.verificaSeExistePersonalizacao(res.data.data)===true){
                    this.botaoExcluirFeedback = true;
                }
            });
        },
        verificaSeExistePersonalizacao(data){
            let status = false;
            data.forEach(function(value) {
                if(value.statuspersonalizada===true){
                    status = true;
                }
            });
            return status;
        },
        excluirPersonalizado() {
            modalGenericModel(
                'Tem certeza?',
                'Esta ação fará com que o feedback padrão volte a ser utilizado.',
                require('@/assets/custom-icons/cora-icons/warning.png'),
                'Sim, excluir',
                'Cancelar'
            )
            .then((result) => {
                if (result.value) {
                    let param = {
                        "id_empresa_grupo": this.grupo.id_empresa_grupo,
                        "id_modulo": this.modulo.id_modulo,
                        "chave_questionario": this.questionarioSelecionado.chave,
                    };
                    this.$http
                        .delete(this.$api.ResetarQuestoesPersonalizadas, { params: param })
                        .then((response) => {
                            if (response.status === 200) {
                                modalGenericModel(
                                    'Sucesso!',
                                    'O Feedback foi restaurado ao padrão',
                                    require('@/assets/custom-icons/cora-icons/success.png'),
                                    'Ok'
                                )
                                .then(() => {
                                    this.buscaMensagensGrupo(this.dadosPaginacao.paginaAtual);
                                });
                                this.botaoExcluirFeedback = false;
                            }
                        })
                        .catch((error) => {
                            let erros =
                                error.response.data.error ?? error.response.data.errors;
                            this.handleModalErro(erros);
                        });
                }
            });
        },
        fechaModalEdit() {
            this.showModalEdit = false;
            this.buscaMensagensGrupo(this.dadosPaginacao.paginaAtual);
        }

       
    },
};
</script>

<style>
.cabecalhoLista{
    color:#7030A0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    border-color: #7030A0;
    border-width:1px;
    border-style:solid;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 5px;
  }

  .titulo{
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #5E5873;
  }

  .organizador-questionario-nome-empresa{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin-bottom: -10px;
  }

  .custom-select-business{
    min-width: 180px;
  }
  .custom-select-business::v-deep .vs__dropdown-toggle{
    border: 1px solid #7030A0;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .custom-select-business::v-deep .vs__selected-options{
    padding: 0;
  }
  .custom-select-business::v-deep .vs__search{
    padding: 5px;
    padding-left: 10px;
    max-height: 25px;
  }
  .custom-select-business::v-deep .vs__search::placeholder{
    font-size: 15px;
    color: #7030A0;
  }
  .custom-select-business::v-deep .vs__selected{
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #7030A0 !important;
    padding-left: 10px;
  }
  .custom-select-business.vs--single.vs--open::v-deep .vs__selected{
    position: relative;
  }
  .custom-select-business::v-deep .feather-chevron-down{
    color: #7030A0;
  }
  .spacing-between-answers{
    padding: 0 1rem 10px 1rem;
  }
</style>
