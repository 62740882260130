<template>
  <b-alert
    :variant="variant"
    show
  >
    <div
      class="alert-body"
    >
      <span>
        <strong :class="getColorTitle">
          {{ title }}
        </strong>

        {{ message }}
      </span>
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: 'success',
    },
  },

  computed: {
    getColorTitle() {
      if (this.variant === 'success') {
        return 'color-msg-success'
      }

      return ''
    },
  },
}
</script>

