<template>
  <div id="grupo-pessoas-importar">
      <h1>Importar Pessoas</h1>
    <IntegracaoPlanilha
      :Grupo="grupo"
      :Configuracao="Pessoa"
      :key="Pessoa.id"
    />
    <hr class="mt-2 mb-2"/>
    <h2>Importar Perfil</h2>
    <IntegracaoPlanilha
      :Grupo="grupo"
      :Configuracao="Perfil"
      :key="Perfil.id"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormFile, BForm, BButton } from "bootstrap-vue";
import IntegracaoPlanilha from "@/views/components/custom/upload/IntegracaoPlanilha.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BForm,
    BButton,
    IntegracaoPlanilha,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Pessoa: {
        id: "pessoa",
        titulo: "Importar Pessoas",
        linkDownload: this.$helpers.urlSite('/planilhas/planilha_modelo_pessoa.xlsx'),
        rotaApi: this.$api.ImportarPlanilhaColaboradores,
      },
      Perfil: {
        id: "perfil",
        titulo: "Importar Perfil",
        linkDownload: this.$helpers.urlSite('/planilhas/planilha_modelo_perfil.xlsx'),
        rotaApi: this.$api.ImportarPlanilhaUsuarios,
      },
    };
  },
};
</script>
